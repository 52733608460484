<template>
    <div class="product-image-carousel mb-4 mb-xl-6" v-if="currentCarouselData">
        <!-- Single image -->
        <template v-if="Object.keys(currentCarouselData.images).length === 1">
            <div class="image-large">
                <img :src="Object.values(currentCarouselData.images)[0]" alt="" />
            </div>
        </template>

        <!-- Multiple images -->
        <template v-else-if="Object.keys(currentCarouselData.images).length > 1">
            <div class="thumbs-wrapper">
                <div class="thumbs">
                    <a
                        href="#"
                        class="thumb"
                        :class="{ active: activeThumb === key }"
                        v-for="(url, key) in currentCarouselData.images"
                        :key="key"
                        @click.prevent="setActiveThumb(key)"
                    >
                        <img :src="url" :alt="`Image ${key}`" />
                    </a>
                </div>
            </div>

            <slick
                ref="slick"
                class="image-carousel"
                :options="slickOptions"
                @afterChange="handleAfterChange"
            >
                <div
                    class="image-large"
                    v-for="(url, key) in currentCarouselData.images"
                    :key="key"
                >
                    <img :src="url" :alt="`Image ${key}`" />
                </div>
            </slick>
        </template>

        <div class="mt-n2"></div>
    </div>
</template>

<script>
import Slick from '../../plugins/slick.js';
import { EventBus } from "../../api/eventBus.ts";

export default {
    components: { Slick },
    props: {
        carouselsJson: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            carousels: [],
            currentCarousel: null,
            activeThumb: null, // New state to track active thumbnail
            slickOptions: {
                swipe: true,
                dots: true,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        };
    },
    computed: {
        currentCarouselData() {
            return this.currentCarousel !== null
                ? this.carousels.find(carousel => carousel.id === this.currentCarousel)
                : null;
        },
    },
    created() {
        // Parse the JSON string into an object
        const carouselsObject = JSON.parse(this.carouselsJson);

        // Transform the object into an array
        this.carousels = Object.values(carouselsObject);

        // Set the default carousel to the first one
        if (this.carousels.length > 0) {
            this.currentCarousel = this.carousels[0].id;
            this.activeThumb = Object.keys(this.carousels[0].images)[0]; // Set the first image as active
        }
        EventBus.$on("variant-selected", this.changeCarousel);
    },
    beforeDestroy() {
        EventBus.$off("variant-selected", this.changeCarousel);
    },
    methods: {
        changeCarousel(index) {
            let newCarousel = this.carousels.find(item => item.id === index);
            // Ensure the index is valid
            if (newCarousel) {
                this.currentCarousel = undefined;

                // Re-init Slick
                this.$nextTick(() => {
                    this.currentCarousel = newCarousel.id;
                    this.activeThumb = Object.keys(newCarousel.images)[0]; // Set the first image as active

                    if (this.$refs.slick) {
                        // console.log("Re-initializing Slick");
                        this.$refs.slick.reSlick();
                    }
                });
            } else {
                console.error("Invalid carousel index:", index);
            }
        },
        setActiveThumb(id) {
            this.activeThumb = id;
            this.$nextTick(() => {
                const index = Object.keys(this.currentCarouselData.images).indexOf(id);
                if (this.$refs.slick) {
                    // console.log("Navigating to slide:", index);
                    this.$refs.slick.goTo(index);
                }
            });
        },
        handleAfterChange(event, slick, currentSlide) {
            const imageKeys = Object.keys(this.currentCarouselData.images);
            this.activeThumb = imageKeys[currentSlide];
        },
    },
};
</script>
