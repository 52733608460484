<template>
    <div>
        {{ currentVariantData }}
    </div>
</template>

<script>
import { EventBus } from "../../api/eventBus.ts";

export default {
    props: {
        variantJson: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            variants: [],
            activeVariant: null,
        };
    },
    computed: {
        currentVariantData() {
            return this.activeVariant !== null
                ? this.variants.find(variant => variant.id === this.activeVariant)
                : null;
        },
    },
    created() {
        // Parse the JSON string into an object
        this.variants = JSON.parse(this.variantJson);

        EventBus.$on("variant-selected", this.changeVariant);
    },
    beforeDestroy() {
        EventBus.$off("variant-selected", this.changeVariant);
    },
    methods: {
        changeVariant(id) {
            this.activeVariant = id;
        }
    }
};
</script>
