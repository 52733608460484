<template>
    <div>
      <transition name="fade">
        <div v-if="visible" :class="['alert', alertClass, 'fixed-bottom', 'alert-container']" role="alert">
          <component :is="alertIcon" class="mr-2" :size="20"></component>
          {{ message }}
        </div>
      </transition>
    </div>
  </template>

  <script>
  import { EventBus } from "../../api/eventBus.ts";
  import { PhCheckCircle, PhXCircle, PhWarningCircle, PhDotsThreeCircle } from "phosphor-vue";

  export default {
    data() {
      return {
        message: "",
        alertClass: "",
        visible: false,
      };
    },
    created() {
      EventBus.$on("trigger-alert", this.showAlert);
    },
    methods: {
      showAlert({ message, state }) {
        this.message = message;
        this.alertClass = this.getAlertClass(state);
        this.visible = true;

        setTimeout(() => {
          this.visible = false;
        }, 4000);
      },
      getAlertClass(state) {
        switch (state) {
          case "success":
            return "alert-success";
          case "warning":
            return "alert-warning";
          case "error":
            return "alert-danger";
          default:
            return "alert-info";
        }
      },
    },
    computed: {
      alertIcon() {
        switch (this.alertClass) {
          case "alert-success":
            return PhCheckCircle;
          case "alert-warning":
            return PhWarningCircle;
          case "alert-danger":
            return PhXCircle;
          default:
            return PhDotsThreeCircle;
        }
      },
    },
    beforeDestroy() {
      EventBus.$off("trigger-alert", this.showAlert);
    },
  };
  </script>

  <style scoped>
  .alert-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
    width: auto;
    opacity: 1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.25s ease-in-out, bottom 0.25s ease-in-out;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
    bottom: -100px;
  }
  </style>
