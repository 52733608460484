import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  // function all_full_form_blocks_init() {
  //   $('.content-block_full_form').each(function() {
  //     block_init($(this));
  //   });
  // }

  // function block_init($block) {
  //   var $card = $block.find('.card:eq(0)');
  //   var $iframe = $block.find('iframe:eq(0)');

  //   var height_desktop = Number($card.data('iframe-height-desktop'));
  //   var height_mobile = Number($card.data('iframe-height-mobile'));

  //   function iframe_height_manager() {
  //     if(window.innerWidth > 992) {
  //       $iframe.height(height_desktop);
  //       console.log('DESKTOP');
  //     } else {
  //       $iframe.height(height_mobile);
  //       console.log('MOBILE');
  //     }
  //   }

  //   $(window).on('resize', function() {
  //     iframe_height_manager();
  //   });

  //   iframe_height_manager();
  // }

  // all_full_form_blocks_init();

  /////////////////////////////

    iFrameResize({
      checkOrigin: [""],
      resizeFrom: parent,
      scrolling: false,
      inPageLinks: true,
      sizeHeight: true,
      autoResize: true,
      log: false
    });

});
