<template>
    <form :class="{ 'touched': formSubmitted }" @submit.prevent="forgotPassword">
        <div class="field">
            <label for="inputEmail" class="form-label">{{ $t('account.label_email') }}</label>
            <input
                type="email"
                class="form-control form-control-lg"
                id="inputEmail"
                autocomplete="email"
                required
                v-model="email"
            />
        </div>

        <div class="row g-2">
            <div class="col-12 col-md-6 order-md-last">
                <button type="submit" class="btn btn-primary w-100" @click="formSubmitted = true">
                    <span v-if="isLoading" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-if="!isLoading">{{ $t('account.btn_send_email') }}</span>
                </button>
            </div>

            <div class="col-12 col-md-6">
                <a :href="btnLink" class="btn btn-white w-100">
                    {{ $t('account.btn_back') }}
                </a>
            </div>
        </div>
    </form>
</template>

<script>
import shopware from '../../mixins/shopware';
import { EventBus } from '../../api/eventBus.ts';

export default {
    mixins: [shopware],
    props: {
        btnLink: String,
    },
    data() {
        return {
            email: '',
            isLoading: false,
            formSubmitted: false
        };
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            await this.getContext();
        },

        async forgotPassword() {
            try {
                this.isLoading = true;

                const session = await this.api.client.invoke("sendRecoveryMail post /account/recovery-password", {
                    email: this.email,
                    storefrontUrl: this.getStoreFrontUrl()
                });

                this.isLoading = false;

                EventBus.$emit('trigger-alert', {
                    message: this.$t('messages.reset_password_request_success'),
                    state: 'success'
                });

                // Redirect to the target URL
                if (this.btnLink) {
                    setTimeout(() => {
                        window.location.href = this.btnLink;
                    }, 4000);
                }

            }
            catch (error) {
                this.isLoading = false;

                EventBus.$emit('trigger-alert', {
                    message: this.getErrorMessages(error)[0],
                    state: 'error'
                });
            }
        }
    }
}
</script>
