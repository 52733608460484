// Get all div elements with the class "bard-with-components" or "bard-content"
var bardDivs = document.querySelectorAll('.bard-with-components, .bard-content');

// Loop through each bard div
bardDivs.forEach(function(bardDiv) {

    // Get all tables within the bard div
    var tables = bardDiv.querySelectorAll('table:not(.ignore-js-table)');

    // Loop through each table found
    for (var i = 0; i < tables.length; i++) {
        var table = tables[i];

        // Wrap the table with a div with class "table-responsive"
        var wrapperDiv = document.createElement('div');
        wrapperDiv.className = 'table-responsive';
        table.parentNode.insertBefore(wrapperDiv, table);
        wrapperDiv.appendChild(table);
    }
});
