<template>
    <div>
        <div v-if="isLoading" class="text-center">
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>

        <div class="payment-method-inputs" v-if="paymentMethods && !isLoading">
            <div class="form-check" v-for="method in paymentMethods" :key="method.id">
                <input class="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    :id="'payment' + method.id"
                    :value="method.id" v-model="selectedPaymentMethod"
                    @change="handlePaymentMethodChange"
                />
                <label class="form-check-label" :for="'payment' + method.id">
                    <img v-if="method.media" class="thumb" :src="method.media.url" :alt="method.translated.name + ' - Thumb'">
                    <div>
                        <span class="name">{{ method.translated.name }}</span>
                        <small v-if="method.translated?.description" class="description">{{ method.translated.description }}</small>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>

import shopware from '../../mixins/shopware';
import { EventBus } from '../../api/eventBus.ts';
import { PhTrash } from 'phosphor-vue';

export default {
    name: 'Cart',
    mixins: [shopware],
    components: {
        PhTrash
    },
    props: {
        cart: Object|null
    },
    data() {
        return {
            internalCart: null,
            paymentMethods: [],
            isLoading: false,
            isLoggedIn: false,
            isUpdatingPaymentMethod: false,
            selectedPaymentMethod: null
        }
    },
    computed:{
        cartData() {
            return this.internalCart || this.cart;
        }
    },
    watch: {
        'cart.price.totalPrice': function(newVal) {
            this.init();
        }
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            this.isLoading = true;

            await this.getContext();

            if (!this.cart) {
                this.getCart();
            }

            const promises = [
                this.getPaymentMethods(),
                this.getProfile()
            ];

            await Promise.all(promises);

            // Set the default payment method based on the context, otherwise default to the first available one
            if (this.cartData && this.context.paymentMethod.id) {
                if (this.paymentMethods.find((method) => method.id === this.context.paymentMethod.id)) {
                    this.selectedPaymentMethod = this.context.paymentMethod.id;
                } else {
                    this.selectedPaymentMethod = this.paymentMethods[0].id;
                    this.updatePaymentMethod(this.selectedPaymentMethod, false);
                }
            }

            this.isLoading = false;
        },

        async getCart() {
            try {
                this.internalCart = await this.api.client.invoke("readCart get /checkout/cart");
            } catch (error) {
                console.error("Error fetching cart:", error);
            }
        },

        async getProfile() {
            try {
                await this.api.client.invoke("readCustomer post /account/customer", {});

                this.isLoggedIn = true;
            } catch (error) {
                this.isLoggedIn = false;
            }
        },

        async getPaymentMethods() {
            try {
                const paymentMethods = await this.api.client.invoke("readPaymentMethod post /payment-method", {
                    onlyAvailable: true
                });

                this.paymentMethods = paymentMethods.elements;
            } catch (error) {
                console.log(error);
            }
        },

        async updatePaymentMethod(paymentMethodId, notify = true) {
            try {
                this.isUpdatingPaymentMethod = true;
                await this.api.client.invoke("updateContext patch /context", {
                    paymentMethodId: paymentMethodId
                });

                if (notify) {
                    EventBus.$emit('trigger-alert', {
                        message: this.$t('messages.changed_payment_method'),
                        state: 'success'
                    });
                }

                await this.getCart();
            } finally {
                this.isUpdatingPaymentMethod = false;
            }
        },

        async handlePaymentMethodChange(event) {
            const paymentMethodId = event.target.value;
            await this.updatePaymentMethod(paymentMethodId);
        }
    }
}
</script>
