<template>
    <div class="align-middle p-relative">
        <div v-if="cartCount > 0" class="icon dot">
            <p class="cart-count mb-0">{{ cartCount }}</p>
        </div>

        <ph-shopping-cart weight="bold" size="16"/>
    </div>
</template>

<script lang="ts">
import { Api } from "../../api/apiClient";
import { EventBus } from "../../api/eventBus.ts";
import { PhShoppingCart } from "phosphor-vue";

export default {
    name: 'CartCounter',
    components: {
        PhShoppingCart
    },

    data() {
        return {
            cartCount: 0,
            api: new Api(),
        }
    },
    created: function () {
        this.getCart();

        EventBus.$on('cart-updated', (cart) => {
            this.updateCartCount(cart);
        });
    },

    methods: {
        async getCart() {
            try {
                this.cart = await this.api.client.invoke("readCart get /checkout/cart");

                this.updateCartCount(this.cart);
            } catch (error) {
                this.cartCount = 0;
            }
        },

        updateCartCount(cart): number {
            this.cartCount = cart.lineItems.filter(item => item.type === "product").length;
        },
    }
}
</script>
