import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function hover_nav_init($nav) {
    $nav.find('.nav-item.dropdown > .nav-link').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
    });
  }

  var $top_nav = $('.navigation.top-navigation');
  var $main_nav = $('.navigation.main-navigation');

  if($top_nav.hasClass('hover-mode')) {
    hover_nav_init($top_nav);
  }

  if($main_nav.hasClass('hover-mode')) {
    hover_nav_init($main_nav);
  }
});
