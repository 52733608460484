import $ from 'jquery'
window.jQuery = window.$ = $

$(function () {

    function assets_carousel_sliders_init() {
        var $assets_carousel_sliders = $('.js-assets_carousel-slider');

        if ($assets_carousel_sliders && $assets_carousel_sliders.length) {
            $assets_carousel_sliders.each(function () {
                assets_carousel_slider_init($(this));
            });
        }
    }

    function assets_carousel_slider_init($slider) {
        var $slides = $slider.find('.slides');

        $slides.slick({
            swipe: false,
            dots: false,
            arrows: false,
            infinite: true,
            fade: false,
            autoplay: true,

            autoplaySpeed: 0,
            cssEase: 'linear',
            speed: 15000,

            variableWidth: true,

            slidesToShow: 4,
            centerMode: true,
            pauseOnHover: false,
        });
    }

    assets_carousel_sliders_init();
});
