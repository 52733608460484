<template>
    <div v-if="variants.length > 0">
        <hr />
        <div class="row g-2">
            <div class="col-6 col-md-auto" v-for="variant in variants" :key="variant.id">
                <a
                    href="#"
                    class="btn btn-variation"
                    :class="{ 'active': activeVariant === variant.id }"
                    @click.prevent="selectVariant(variant.id)"
                >
                    <template v-if="variant.coverage && variant.coverage.trim() !== '-'">
                        <span>{{ variant.coverage }}</span>
                        <hr/>
                    </template>
                    <span class="lg">{{ getVariantLabel(variant) }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "../../api/eventBus.ts";

export default {
    props: {
        variantJson: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            variants: [],
            activeVariant: null,
        };
    },
    created() {
        // Parse the JSON string into an object
        this.variants = JSON.parse(this.variantJson);

        // Set the default active variant if necessary
        if (this.variants.length > 0) {
            this.activeVariant = this.variants[0].id;
            this.$nextTick(() => {
                EventBus.$emit("variant-selected", this.activeVariant);
                Livewire.dispatch('variant-selected', {id: this.activeVariant});
            });
        }
    },
    methods: {
        selectVariant(id) {
            this.activeVariant = id;
            this.$nextTick(() => {
                EventBus.$emit("variant-selected", id);
                Livewire.dispatch('variant-selected', {id: this.activeVariant});
            });
        },
        getVariantLabel(variant) {
            return (
                variant.data.base_unit ||
                variant.data.Oppervlakte_bereik ||
                "Unknown"
            );
        }
    }
};
</script>
