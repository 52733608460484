export default {
    methods: {
        statusCheck(status) {
            if (status) {
                return status === 'product_in_stock' || status === 'soil_too_dry';
            } else {
                return true;
            }
        }
    }
};
