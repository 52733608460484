import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_links_init() {
        $('a').on('click', function(event) {
            var $link = $(this);
            var id = $link.attr('href');

            if(String(id).includes('#')) {
                if(!hash_check(id, event)) {
                    id = id.replace(window.location.pathname, '');
                    hash_check(id, event);
                }
            }
        });
    }

    function hash_check(id, event = null) {
        if((String(id).indexOf('#')) === 0) {
            event?.preventDefault();
            flyToContent(id);
            return true;
        } else {
            return false;
        }
    }

    function all_scroll_selects_init() {
        $('.js-scroll-nav select').each(function() {
            select_eventListeners($(this));
        });
    }

    function select_eventListeners($select) {
        $select.on('change', function(event) {
            event.preventDefault();

            setTimeout(function() {
                flyToContent($select.val());
            },500);

            setTimeout(function() {
                $select.val('');
            },1000);
        });
    }

    function flyToContent(id) {
        if(String(id).includes('#') && (String(id).indexOf('#')) === 0) {
            id = String(id).replace('#', '#block-');
        } else {
            return;
        }

        var $target = $(id);

        if(!$target || !$target.length) {
            return;
        }

        var offset = Math.round($target.offset().top);

        if(window.innerWidth > 1600) {
            if(!$target.hasClass('with-bg')) {
                offset -= 140; //extra breathingspace
            }
        } else if(window.innerWidth > 768) {
            if(!$target.hasClass('with-bg')) {
                offset -= 40; //extra breathingspace
            }
        } else {
            if(!$target.hasClass('with-bg')) {
                offset -= 25; //extra breathingspace
            }
        }

        // Safari
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if(isSafari) {
            $('body,html').animate({
                scrollTop: offset
            });
        } else {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }
    }

    function check_currentUrl() {
        if(window.location && window.location.hash) {
            flyToContent(window.location.hash);
        }
    }

    setTimeout(all_links_init, 50);
    setTimeout(all_scroll_selects_init, 50);
    setTimeout(check_currentUrl, 100);
});
