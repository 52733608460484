<template>
    <form :class="{ 'touched': formSubmitted }" @submit.prevent="login">
        <div class="field">
            <label for="inputEmail" class="form-label">{{ $t('account.label_email') }}</label>
            <input
                type="email"
                class="form-control form-control-lg"
                id="inputEmail"
                autocomplete="email"
                required
                v-model="username"
            >
        </div>

        <div class="field">
            <label for="inputPassword" class="form-label">{{ $t('account.label_password') }}</label>
            <input
                type="password"
                class="form-control form-control-lg"
                id="inputPassword"
                autocomplete="current-password"
                required
                v-model="password"
            />
        </div>

        <button class="btn btn-primary w-100" @click="formSubmitted = true">
            <span v-if="isLoading" class="spinner-border" role="status" aria-hidden="true"></span>
            <span v-if="!isLoading">{{ $t('account.btn_login') }}</span>
        </button>
    </form>
</template>

<script>
import shopware from '../../mixins/shopware';
import { EventBus } from '../../api/eventBus.ts';

export default {
    mixins: [shopware],
    props: {
        btnLink: String,
    },
    data() {
        return {
            username: '',
            password: '',

            isLoading: false,
            formSubmitted: false
        };
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            await this.api.updateContext();
        },

        async login() {
            try {
                this.isLoading = true;

                await this.api.client.invoke("loginCustomer post /account/login", {
                    username: this.username,
                    password: this.password
                });

                // Redirect to the target URL
                if (this.btnLink) {
                    window.location.href = this.btnLink;
                }
            } catch (error) {
                this.isLoading = false;

                EventBus.$emit('trigger-alert', {
                    message: this.getErrorMessages(error)[0],
                    state: 'error'
                });
            }
        }
    }
};
</script>
