import $ from 'jquery'
window.jQuery = window.$ = $

var $gallery_carousel_sliders = $('.js-gallery_carousel-slider');
var $collection_carousel_sliders = $('.js-collection_carousel-slider');

$(function () {

    function gallery_carousel_sliders_init() {
        if ($gallery_carousel_sliders && $gallery_carousel_sliders.length) {
            $gallery_carousel_sliders.each(function () {
                gallery_carousel_slider_init($(this));
            });
        }

        if ($collection_carousel_sliders && $collection_carousel_sliders.length) {
            $collection_carousel_sliders.each(function () {
                collection_carousel_slider_init($(this));
            });
        }
    }

    function gallery_carousel_slider_init($slider) {
        var $slides = $slider.find('.slides');

        $slides.slick({
            swipe: true,
            dots: false,
            variableWidth: true,
            infinite: true,
            fade: false,
            centerMode: false,

            prevArrow: $slider.find('.custom-arrows .slick-prev'),
            nextArrow: $slider.find('.custom-arrows .slick-next'),
        });
    }

    function collection_carousel_slider_init($slider) {
        var $slides = $slider.find('.slides');

        $slides.slick({
            swipe: true,
            dots: false,
            variableWidth: false,
            infinite: false,
            fade: false,
            centerMode: false,
            slidesToShow: 3,

            prevArrow: $slider.find('.custom-arrows .slick-prev'),
            nextArrow: $slider.find('.custom-arrows .slick-next'),

            responsive: [
                {
                    // XS - SM
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    // SM - MD
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.3
                    }
                },
                {
                    // MD - LG
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    // LG- XL
                    breakpoint: 1360,
                    settings: {
                        slidesToShow: 2.5
                    }
                },
                {
                    // XL - XXL
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });
    }

    gallery_carousel_sliders_init();
});
