import { RequestParameters, RequestReturnType, createAPIClient } from "@shopware/api-client";
import type { operationPaths, operations, components } from "../../../../node_modules/@shopware/api-client/api-types";
// @ts-ignore
import Cookies from "js-cookie";

const cookieConfig = {
    expires: 365, // days
    path: "/",
    sameSite: "lax",
};

export class Api {
    public client = createAPIClient<operations, operationPaths>({
        baseURL: window.Shopware.StorefrontUrl,
        accessToken: window.Shopware.AccessToken,
        contextToken: Cookies.get("sw-context-token"),
        async onContextChanged(newContextToken) {
            Cookies.set("sw-context-token", newContextToken, cookieConfig);
        }
    });

    public updateContext = async () => {
        const browserLanguage = document.querySelector('meta[name="iso-639-3166"]')?.getAttribute('value');
        const cookieLanguage = Cookies.get("sw-iso-639-3166");

        if (cookieLanguage && cookieLanguage.includes(browserLanguage)) {
            return;
        }

        const languages = await this.client.invoke('readLanguages post /language', {});

        if (languages?.elements && languages?.elements?.length > 0) {
            const currentLanguage = languages?.elements.find(l => l.translationCode?.code === browserLanguage);

            if (currentLanguage) {
                await this.client.invoke('updateContext patch /context', {
                    languageId: currentLanguage.id
                });

                Cookies.set("sw-iso-639-3166", `${currentLanguage.id}_${browserLanguage}`, cookieConfig);
            }
        }
    }

    public getCookie = (cookieName: string) => Cookies.get(cookieName);
}

declare global {
    interface Window { Shopware: any; }
}

// reimport schemas to use it in application
export type ApiSchemas = components["schemas"];
// reimport operations request parameters to use it in application
export type ApiRequestParams<OPERATION_NAME extends keyof operations> =
    RequestParameters<OPERATION_NAME, operations>;
// reimport operations return types to use it in application
export type ApiReturnType<OPERATION_NAME extends keyof operations> =
    RequestReturnType<OPERATION_NAME, operations>;
