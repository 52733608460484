<template>
    <div>
        <h4 class="mb-2">
            {{ addressLabel ?? $t('checkout.info_address_info_title') }}
        </h4>

        <div class="row gy-1 gx-2 gx-md-2 gx-xl-3 mb-2">

            <!-- firstname -->
            <div v-if="showNameFields" class="col-12 col-md-6">
                <div class="field m-0">
                    <label for="inputFirstName" class="form-label">{{ $t('account.label_first_name') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputFirstName"
                        autocomplete="given-name"
                        required
                        :value="firstName"
                        @input="$emit('update:firstName', $event.target.value)"
                    />
                </div>
            </div>
            <!-- end firstname -->

            <!-- lastname -->
            <div v-if="showNameFields" class="col-12 col-md-6">
                <div class="field m-0">
                    <label for="inputLastName" class="form-label">{{ $t('account.label_last_name') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputLastName"
                        autocomplete="family-name"
                        required
                        :value="lastName"
                        @input="$emit('update:lastName', $event.target.value)"
                    />
                </div>
            </div>
            <!-- end lastname -->

            <!-- street -->
            <div class="col-12">
                <div class="field m-0">
                    <label for="inputStreet" class="form-label">{{ $t('account.label_street') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputStreet"
                        :value="street"
                        autocomplete="street-address"
                        pattern="^(.+)\s(\d+(\s*[^\d\s]+)*)$"
                        required
                        @input="$emit('update:street', $event.target.value)"
                    />
                </div>
            </div>
            <!-- end street -->

            <div class="col-12 d-none d-md-block"></div>

            <!-- zipcode -->
            <div class="col-12 col-md-4">
                <div class="field m-0">
                    <label for="inputZipcode" class="form-label">{{ $t('account.label_zip') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputZipcode"
                        :value="zipcode"
                        autocomplete="postal-code"
                        :required="config('postalCodeRequired', true)"
                        :pattern="postalCodePattern"
                        @input="$emit('update:zipcode', $event.target.value)"
                    />
                </div>
            </div>
            <!-- end zipcode -->

            <!-- city -->
            <div class="col-12 col-md-8">
                <div class="field m-0">
                    <label for="inputCity" class="form-label">{{ $t('account.label_city') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputCity"
                        :value="city"
                        autocomplete="address-level2"
                        required
                        @input="$emit('update:city', $event.target.value)"
                    />
                </div>
            </div>
            <!-- end city -->

            <!-- Country -->
            <div class="col-12 col-md-6" :class="{ 'd-none': countries.length <= 1 }">
                <div class="field m-0">
                    <label for="countryId" class="form-label">{{ $t('account.label_country') }}</label>
                    <select
                        id="countryId"
                        class="form-select form-control-lg"
                        v-model="countryId"
                        required
                        @change="$emit('update:countryId', $event.target.value)"
                    >
                        <option v-for="country in countries" :key="country.id" :value="country.id">
                            {{ country.translated.name }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- End Country -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerAddress',
    props: {
        showNameFields: {
            type: Boolean,
            required: false,
            default: true
        },
        addressLabel: {
            type: String,
            required: false,
            default: null
        },
        firstName: {
            type: String,
            required: false,
            default: ''
        },
        lastName: {
            type: String,
            required: false,
            default: ''
        },
        street: {
            type: String,
            required: false,
            default: ''
        },
        zipcode: {
            type: String,
            required: false,
            default: ''
        },
        city: {
            type: String,
            required: false,
            default: ''
        },
        countryId: {
            type: String,
            required: false,
            default: null
        },
        countries: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        selectedCountry() {
            if (!this.countryId) return;

            return this.countries.find(country => country.id === this.countryId);
        },
        postalCodePattern() {
            if (this.config('checkAdvancedPostalCodePattern') && this.config('advancedPostalCodePattern')) {
                return this.config('advancedPostalCodePattern');
            }

            if (this.config('checkPostalCodePattern') && this.config('defaultPostalCodePattern')) {
                return this.config('defaultPostalCodePattern');
            }

            return '(.*?)';
        }
    },
    methods: {
        config(key, fallback = null) {
            const configuration = this.selectedCountry;

            if (configuration && key in configuration) {
                return configuration[key];
            } else {
                return fallback;
            }
        }
    }
}
</script>
