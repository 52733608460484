<template>
    <div>
        <div v-if="isUpdated">
            <h2 class="mb-2">{{ $t('account.password_reset_title') }}</h2>
            <p>{{ $t('account.password_reset_text') }}</p>

            <div class="pb-1"></div>

            <div class="form-style form-light custom">
                <div class="pt-1"></div>

                <a :href="btnLink" class="btn btn-primary">{{ $t('account.btn_back_to_login') }}</a>
            </div>
        </div>

        <div v-if="!isUpdated">
            <h2 class="mb-2">{{ $t('account.password_reset_choose_new_password') }}</h2>

            <div class="pb-1"></div>

            <div class="form-style form-light custom">
                <form :class="{ 'touched': formSubmitted }" @submit.prevent="resetPassword">
                    <div :class="['field m-0', passwordsMatchInvalid || passwordLengthInvalid ? 'required' : '']">
                        <label for="inputPassword" class="form-label">{{ $t('account.label_password') }}</label>
                        <input
                            type="password"
                            class="form-control form-control-lg"
                            id="inputPassword"
                            autocomplete="new-password"
                            required
                            v-model="password"
                            :placeholder="$t('account.label_password_placeholder')"
                        />

                        <span v-if="passwordLengthInvalid" class="error-message small text-danger">
                            {{ $t('error.password_length') }}
                        </span>
                    </div>

                    <div :class="['field', passwordsMatchInvalid ? 'required' : '']">
                        <label for="inputPasswordConfirm" class="form-label">{{ $t('account.label_confirm_password') }}</label>
                        <input
                            type="password"
                            class="form-control form-control-lg"
                            id="inputPasswordConfirm"
                            autocomplete="new-password"
                            required
                            v-model="passwordConfirm"
                        />

                        <span v-if="passwordsMatchInvalid" class="error-message small text-danger">
                            {{ $t('error.passwords_must_match') }}
                        </span>
                    </div>


                    <div class="row g-2">
                        <div class="col-12 col-md-6 order-md-last">
                            <button type="submit" class="btn btn-primary w-100" @click="formSubmitted = true">
                                <span v-if="isLoading" class="spinner-border" role="status" aria-hidden="true"></span>
                                <span v-if="!isLoading">{{ $t('account.btn_change_pw') }}</span>
                            </button>
                        </div>

                        <div class="col-12 col-md-6">
                            <a :href="btnLink" class="btn btn-white w-100">
                                {{ $t('account.btn_back') }}
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import shopware from '../../mixins/shopware';
import { EventBus } from '../../api/eventBus.ts';

export default {
    mixins: [shopware],
    props: {
        btnLink: String,
    },
    data() {
        return {
            isLoading: false,
            isUpdated: false,
            formSubmitted: false,

            password: null,
            passwordConfirm: null,
            hash: null
        };
    },
    computed: {
        passwordMinLength() {
            return this.config('passwordMinLength', 8);
        },
        passwordLengthInvalid() {
            return this.password && this.password.length < this.passwordMinLength;
        },
        passwordsMatchInvalid() {
            return this.password && this.passwordConfirm && this.password !== this.passwordConfirm;
        },
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            await this.getContext();

            const url = new URL(window.location.href);
            this.hash = url.searchParams.get("hash");

            if (!this.hash && this.btnLink) {
                // window.location.href = this.btnLink;
            }
        },

        async resetPassword() {
            try {
                this.isLoading = true;

                await this.api.client.invoke("recoveryPassword post /account/recovery-password-confirm", {
                    hash: this.hash,
                    newPassword: this.password,
                    newPasswordConfirm: this.passwordConfirm
                });

                this.isLoading = false;
                this.isUpdated = true;
            }
            catch (error) {
                this.isLoading = false;

                EventBus.$emit('trigger-alert', {
                    message: this.getErrorMessages(error)[0],
                    state: 'error'
                });
            }
        }
    }
}
</script>
