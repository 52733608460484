export default {
    methods: {
        formatNumber(value) {
            if (isNaN(value)) return value;

            let formattedValue = parseFloat(value).toFixed(2);
            formattedValue = formattedValue.replace('.', ',');

            return formattedValue;
        },

        formatVoucher(code) {
            return code.match(/.{1,4}/g).join('-');
        }
    }
};
