import { Api } from '../api/apiClient';

export default {
    data() {
        return {
            api: new Api(),
            context: {},
        }
    },
    methods: {
        async initContext() {
            await this.api.updateContext();
            await this.getContext();
        },

        async getContext() {
            const context = await this.api.client.invoke("readContext get /context");
            this.context = context;
        },

        config(key, fallback = null) {
            const configuration = this.context.salesChannel?.configuration;

            if (configuration && key in configuration) {
                return configuration[key];
            } else {
                return fallback;
            }
        },

        getStoreFrontUrl() {
            const languageId = this.api.getCookie('sw-iso-639-3166')?.split('_')?.[0] ?? this.context?.languageId ?? '';

            return this.context?.salesChannel.domains.find(domain => domain.languageId === languageId)?.url ?? '';
        },

        getErrorMessages(error, params = {}) {
            const errors = error?.details?.errors;

            if (errors) {
                return errors.map(error => this.getErrorMessage(error, params));
            } else {
                return [this.$t('error.generic')];
            }
        },

        getErrorMessage(error, params = {}) {
            const prefix = 'error.shopware.';
            let key = prefix;

            // get Shopware error message based on the provided keys
            if ('messageKey' in error && error.messageKey) {
                key += error.messageKey;
            } else if ('key' in error && error.key) {
                key += error.key;
            } else if ('code' in error && error.code) {
                key += error.code;
            }

            let message = this.$t(key, params);

            // when no key translation is found, try to get translation without the prefix
            if (message === key) {
                message = this.$t(key.split(prefix)[1], params);
            }

            // when no key translation is found, fallback default Shopware message (or show a general error message)
            if (message === key) {
                if ('message' in error && error.message) {
                    message = error.message;
                } else if ('detail' in error && error.detail) {
                    message = error.detail;
                } else {
                    message = this.$t('error.generic');
                }
            }

            return message;
        },

        createUUID() {
            return crypto.randomUUID().replaceAll('-', '');
        }
    }
}
