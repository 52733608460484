<template>
    <div class="cart-summary">
        <div class="line labels">
            <div class="line-col product">{{ $t("checkout.cart_label_product") }}</div>
            <div class="line-col count">{{ $t("checkout.cart_label_count") }}</div>
            <div class="line-col item-price">{{ $t("checkout.cart_label_item_price") }}</div>
            <div class="line-col line-price">{{ $t("checkout.cart_label_line_price") }}</div>
            <div class="line-col remove">&nbsp;</div>
        </div>

        <div class="line item" v-for="lineItem in cartData.lineItems" :key="lineItem.id">
            <div class="line-col product">
                <div class="thumb">
                    <img v-if="lineItem.type == 'product'" :src="lineItem?.payload.coverImg ? lineItem?.payload.coverImg : '/images/product-placeholder.jpg'" />
                </div>
                <div v-if="isVoucher(lineItem)" class="meta">
                    <p class="title">{{ lineItem?.label }}</p>
                </div>
                <div v-else class="meta">
                    <a v-if="lineItem?.payload.productUrl" :href="lineItem?.payload.productUrl" class="title">
                        {{ lineItem?.label }}
                        <span v-if="lineItem?.payload?.variant">({{ lineItem?.payload?.variant }})</span>
                    </a>
                    <p v-else class="title">
                        {{ lineItem?.label }}
                        <span v-if="lineItem?.payload?.variant">({{ lineItem?.payload?.variant }})</span>
                    </p>

                    <p v-if="lineItem?.payload.description" class="description mb-0">{{ lineItem?.payload?.description }}</p>
                    <small v-if="lineItem?.payload.productVoucherCodeProduct" class="info">{{ $t("checkout.cart_label_voucher") }} {{ formatVoucher(lineItem?.payload.productVoucherCodeProduct) }}</small>
                    <small v-if="showStockStatus(lineItem)" class="warning">{{ $t(`products.${lineItem.payload.stockStatus}_description`) }}</small>
                </div>
            </div>
            <div class="line-col count">
                <input
                    v-if="canUpdateQuantity(lineItem)"
                    type="number"
                    class="form-control form-control-sm"
                    :value="lineItem.quantity"
                    min="1"
                    @change="debouncedUpdateQuantity(lineItem.id, $event.target.value)"
                />
                <span class="small" v-else>{{ lineItem.quantity }}</span>
            </div>
            <div class="line-col item-price">
                <span>&euro;{{ formatNumber(lineItem?.price?.unitPrice) }}</span>
            </div>
            <div class="line-col line-price">
                <span v-if="!isUpdatingQuantity(lineItem.id)">&euro;{{ formatNumber(lineItem?.price?.totalPrice) }}</span>
                <span v-if="isUpdatingQuantity(lineItem.id)" class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
            </div>
            <div class="line-col remove">
                <button v-if="lineItem.removable" class="btn remove-link" @click="removeLineItem(lineItem.id)">
                    <ph-trash />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { PhTrash } from 'phosphor-vue';
import _ from 'lodash';
import { Api } from '../../api/apiClient';
import { EventBus } from '../../api/eventBus.ts';
import formatters from '../../mixins/formatters';
import lineitem from '../../mixins/line-item';

export default {
    name: "CartLineItems",
    mixins: [lineitem, formatters],
    components: {
        PhTrash,
    },
    props: {
        cart: Object,
    },
    data() {
        return {
            api: new Api(),
            internalCart: null,
            updatingQuantityItems: [],
        };
    },
    computed: {
        cartData() {
            return this.cart || this.internalCart;
        },
    },
    created() {
        if (!this.cart) {
            this.fetchCart();
        }
    },
    methods: {
        async fetchCart() {
            try {
                this.internalCart = await this.api.client.invoke("readCart get /checkout/cart");
            } catch (error) {
                console.error("Error fetching cart:", error);
            }
        },
        debouncedUpdateQuantity: _.debounce(function (itemId, quantity) {
            this.updateQuantity(itemId, quantity);
        }, 500),
        async updateQuantity(itemId, quantity) {
            if (!Number.isInteger(parseInt(quantity))) {
                return;
            }

            try {
                this.updatingQuantityItems.push(itemId);
                EventBus.$emit('updating-quantity-items', true);

                let items = this.cartData.lineItems
                    .filter(item => this.canUpdateQuantity(item))
                    .map((item) => {
                        if (item.id === itemId) {
                            item.quantity = parseInt(quantity);
                        }

                        return {
                            id: item.id,
                            quantity: parseInt(item.quantity)
                        };
                    });

                const updatedCart = await this.api.client.invoke("updateLineItem patch /checkout/cart/line-item", { items });

                if (this.cart) {
                    this.$emit('update-cart', updatedCart);
                } else {
                    this.internalCart = updatedCart;
                }

                EventBus.$emit("cart-updated", updatedCart);
            } finally {
                this.updatingQuantityItems = this.updatingQuantityItems.filter((id) => id !== itemId);
                EventBus.$emit('updating-quantity-items', false);
            }
        },
        async removeLineItem(itemId) {
            EventBus.$emit('updating-quantity-items', true);
            const updatedCart = await this.api.client.invoke("removeLineItem delete /checkout/cart/line-item", {
                ids: [itemId],
            });

            if (this.cart) {
                this.$emit('update-cart', updatedCart);
            } else {
                this.internalCart = updatedCart;
            }
            EventBus.$emit('updating-quantity-items', false);
            EventBus.$emit("cart-updated", updatedCart);
        },
        canUpdateQuantity(lineItem) {
            if ('productVoucherCodeProduct' in lineItem.payload) {
                return false;
            }

            if (lineItem.type === 'promotion') {
                return false;
            }

            return lineItem.stackable;
        },
        isUpdatingQuantity(itemId) {
            return this.updatingQuantityItems.includes(itemId);
        }
    }
};
</script>
