// Wait for the DOM content to be loaded
document.addEventListener("DOMContentLoaded", function() {
    // Get the card element and the close button
    var card = document.querySelector(".card.slide-in-card");

    if(!card) {
        return;
    }

    var closeButton = card.querySelector(".close");
    var cardBodyBtns = card.querySelector(".card-body .btn");

    // Initialize the state variable
    var slideInCardHidden = localStorage.getItem('slideInCardHidden') === 'true';

    // Function to check the scroll position
    function checkScroll() {
        if (slideInCardHidden) {
            return; // Do nothing if the card was previously hidden
        }

        var scrollPosition = window.scrollY || window.pageYOffset;
        var screenHeight = window.innerHeight;

        // Check if the scroll position is more than 50% of the screen height
        if (scrollPosition > (screenHeight / 2)) {
            card.classList.add('visible');
        } else {
            card.classList.remove('visible');
        }
    }

    // Function to hide the card
    function hideCard() {
        card.classList.remove('visible');
        saveHideCardToStorage();
    }

    function saveHideCardToStorage() {
        slideInCardHidden = true;
        localStorage.setItem('slideInCardHidden', 'true');
    }

    // Check localStorage when the page loads
    if (slideInCardHidden) {
        card.classList.remove('visible');
    } else {
        // Listen to the scroll event
        window.addEventListener('scroll', checkScroll);
    }

    // Listen to the click event on the close button
    closeButton.addEventListener('click', function(event) {
        event.preventDefault();
        hideCard();
    });

    // Listen to the click event on any .btn inside the .card-body
    cardBodyBtns.addEventListener('click', function(event) {
        saveHideCardToStorage();
    });
});
