import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function all_mobile_cards_carousels_init() {
    $('.mobile-cards-as-carousel').each(function() {
      block_init($(this));
    });
  }

  function block_init($row) {
    $row.slick({
      swipe: true,
      autoplay: false,
      nextArrow: false,
      prevArrow: false,
      infinite: false,
      slidesToShow: 1,
      dots: true,
      mobileFirst: true,
      // centerMode: true,
      // centerPadding: '1.5rem',

      responsive: [
        {
          breakpoint: 991,
          settings: "unslick"
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });

    $row.on('setPosition', function () {
      $(this).find('.slick-slide').height('auto');
      var slickTrack = $(this).find('.slick-track');
      var slickTrackHeight = $(slickTrack).height();
      $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
    });
  }

  all_mobile_cards_carousels_init();

  var mql = window.matchMedia('(max-width: 991px)');
  mql.onchange = function(e) {
    if(e.matches) {
      all_mobile_cards_carousels_init();
    }
  }
});
