import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function all_goto_selects_init() {
    $('.js-select-goto-page').each(function() {
      select_eventListeners($(this));
    });
  }

  function select_eventListeners($select) {
    $select.on('change', function(event) {
      event.preventDefault();
      window.location.href = $select.val();
    });
  }

  all_goto_selects_init();
});
