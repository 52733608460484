import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_mobile_items_carousels_init() {
        $('.always-items-carousel').each(function() {
            carousel_init($(this));
        });
    }

    function carousel_init($carousel) {
        var $slider = null;

        var options = createObjectFromString($carousel.data('options') ?? '');

        function defineOptionValue(propertyName, defaultValue) {
            if(options && options.hasOwnProperty(propertyName)) {
                return options[propertyName];
            } else {
                return defaultValue;
            }
        }

        function slider_create() {
            var config = {
                swipe: defineOptionValue('swipe', true),
                dots: defineOptionValue('dots', true),
                arrows: defineOptionValue('arrows', true),
                infinite: defineOptionValue('infinite', false),
                slidesToShow: defineOptionValue('slidesToShow', 4),
                slidesToScroll: defineOptionValue('slidesToShow', 4),
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-lg', 3.1),
                            slidesToScroll: defineOptionValue('slidesToShow-lg', 3),
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-md', 2.1),
                            slidesToScroll: defineOptionValue('slidesToShow-md', 2),
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-sm', 1.1),
                            slidesToScroll: defineOptionValue('slidesToShow-sm', 1),
                        }
                    }
                ]
            };

            $slider = $carousel.slick(config);
            window.fixSlickOverflow($slider);
        }

        slider_create();
    }

    all_mobile_items_carousels_init();
});
