import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_elevator_blocks_init() {
        $('.js-content-block_elevator').each(function() {
            var $block_content = $(this);
            block_init($block_content);
        });
    }

    function block_init($block_content) {
        var $sections = $block_content.find('.js-section');
        var $links = $block_content.find('.js-scroll-nav .link');
        var $images = $block_content.find('.images img');

        var currentSection = null;

        function scrollManager() {
            var scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            var newSection = 0;

            $sections.each(function(i, el) {
                var distance = offset(el).top - scrollTop;
                var trHold = window.innerHeight/1.65;

                if(distance <trHold) {
                    newSection = $sections.index($(el));
                }
            });

            if(currentSection !== newSection) {
                currentSection =newSection;

                $links.removeClass('active');
                $images.removeClass('active');

                $links.eq(currentSection).addClass('active');
                $images.eq(currentSection).addClass('active');

            }
        }

        $links.on('click', function(event) {
            event.preventDefault();

            var $link = $(this);
            var $section = $($link.data('href'));
            var $spacer = $section.find('.spacer-between');

            var offset = Math.round($section.offset().top);
            var height = $section.height();


            if($spacer && $spacer.length) {
                var spacerHeight = $spacer.height();
                height -= spacerHeight;
            }

            offset = offset - (window.innerHeight/2) + (height/2);

            // Safari
            var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if(isSafari) {
                $('body,html').animate({
                    scrollTop: offset
                });
            } else {
                window.scrollTo({top: offset, behavior: 'smooth'});
            }
        });

        scrollManager();

        $(window).on('scroll', function() {
            scrollManager();
        });
    }

    function offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    all_elevator_blocks_init();
});
