<template>
    <div>
        <div :class="{ 'cart-as-sidebar': asSidebar, visible: sidebarVisible }">
            <h1 v-if="!asSidebar" class="mb-2 mb-md-5 mb-xl-6">
                {{ $t("checkout.cart_title") }}
            </h1>

            <div v-if="isLoading" class="text-center">
                <span
                    class="spinner-border spinner-border"
                    role="status"
                    aria-hidden="true"
                ></span>
            </div>

            <div v-if="!isLoading && cart?.lineItems?.length < 1">
                <p>{{ $t("checkout.cart_label_empty") }}</p>
                <a v-if="productsUrl" :href="productsUrl" class="btn btn-secondary btn-sm">{{ $t('products.all_products') }}</a>

                <div class="card coupon-card mt-6">
                        <div class="card-body">
                            <div class="meta">
                                <p class="title">
                                    {{ $t("checkout.prepaid_box_title") }}
                                </p>
                                <p class="text">
                                    {{ $t("checkout.prepaid_box_text") }}
                                </p>
                            </div>
                            <div class="action">
                                <a
                                    href=""
                                    class="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalCodeInput"
                                >
                                    {{ $t("checkout.btn_input_prepaid_box_code") }}
                                </a>
                            </div>
                        </div>
                    </div>
            </div>

            <div
                v-if="!isLoading && cart?.lineItems?.length >= 1"
                class="row justify-content-between g-4 g-xl-2"
            >
                <div class="col-12 col-xl-7 col-cart-summary">
                    <h3 v-if="asSidebar" class="mb-2 mb-md-2 mb-xl-3">
                        {{ $t("checkout.cart_title") }}
                    </h3>

                    <CartLineItems :cart="cart" @update-cart="updateCart" />

                    <div class="card coupon-card mt-3">
                        <div class="card-body">
                            <div class="meta">
                                <p class="title">
                                    {{ $t("checkout.prepaid_box_title") }}
                                </p>
                                <p class="text">
                                    {{ $t("checkout.prepaid_box_text") }}
                                </p>
                            </div>
                            <div class="action">
                                <a
                                    href=""
                                    class="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalCodeInput"
                                >
                                    {{ $t("checkout.btn_input_prepaid_box_code") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xl-4 col-cart-checkout">
                    <div class="card checkout-card checkout-card-delivery">
                        <div class="card-body">
                            <h5 class="mb-3">
                                {{ $t("checkout.delivery_title") }}
                            </h5>

                            <shipment-selector :cart="cart"></shipment-selector>
                        </div>
                    </div>

                    <cart-summary></cart-summary>

                    <a :href="checkoutUrl" class="btn btn-secondary w-100 mt-1">
                        <span v-if="asSidebar">
                            {{ $t("checkout.btn_to_the_register") }}
                        </span>
                        <span v-else>
                            {{ $t("checkout.btn_continue_to_order") }}
                        </span>
                    </a>

                    <div class="payment-method-info mt-1" v-if="paymentMethods && paymentMethods.length > 0 && !asSidebar">
                        <p>{{ $t("checkout.payment_safe_payment_with" )}}</p>
                        <div class="thumbs">
                            <template v-for="method in paymentMethods">
                                <img
                                    v-if="method.media"
                                    :src="method.media.url"
                                    :alt="method.translated.name + ' - Thumb'"
                                />
                            </template>
                        </div>
                    </div>

                    <button
                        v-if="asSidebar"
                        class="btn btn-primary w-100 mt-1"
                        @click="hideSidebar()"
                    >
                        <span>{{ $t("checkout.btn_continue_shopping") }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="cart-as-sidebar-backdrop" :class="{ 'visible': sidebarVisible }"></div>
    </div>
</template>

<script>
import CartLineItems from "./CartLineItems.vue";
import ShipmentSelector from "./ShipmentSelector.vue";
import CartSummary from "./CartSummary.vue";
import shopware from '../../mixins/shopware';
import { EventBus } from "../../api/eventBus.ts";

export default {
    components: {
        CartLineItems,
        ShipmentSelector,
        CartSummary
    },
    mixins: [shopware],
    props: {
        asSidebar: Boolean,
        sidebarContinueUrl: String,
        productsUrl: String,
        checkoutUrlLogin: String,
        checkoutUrlCollectionPoint: String
    },
    data() {
        return {
            cart: null,
            isLoading: false,
            isLoggedIn: false,
            sidebarVisible: false,
            paymentMethods: [],
        };
    },
    created: function () {
        this.init();
    },
    computed: {
        checkoutUrl() {
            if (!this.asSidebar) {
                if (this.isLoggedIn) {
                    return this.checkoutUrlCollectionPoint;
                } else {
                    return this.checkoutUrlLogin;
                }
            } else {
                return this.sidebarContinueUrl;
            }
        },
    },
    methods: {
        async init() {
            this.isLoading = true;

            await this.getContext();

            const promises = [
                this.getCart(),
                this.getProfile(),
                this.getPaymentMethods(),
            ];

            await Promise.all(promises);

            this.isLoading = false;

            if (this.asSidebar ?? null) {
                this.sidebarListener();
            }
        },
        updateCart(updatedCart) {
            this.cart = updatedCart;
        },
        sidebarListener() {
            EventBus.$on("cart-updated", (cart) => {
                if (cart.errors && cart.errors.hasOwnProperty('faulty-voucher-product-combination')) {
                    EventBus.$emit('trigger-alert', {
                        message: this.getErrorMessage(cart.errors['faulty-voucher-product-combination']),
                        state: 'error'
                    });

                    this.hideSidebar();

                    return;
                }

                if (cart.lineItems && cart.lineItems.length > 0) {
                    Promise.all([
                        this.getCart(),
                        this.getProfile(),
                    ]).catch((error) => {
                        console.error("Error while fetching data:", error);
                    });

                    if (!this.sidebarVisible) {
                        setTimeout(() => {
                            this.showSidebar();
                        }, 600);
                    }
                } else {
                    this.hideSidebar();
                }
            });

            document.addEventListener("click", (event) => {
                var flyoutBar = document.querySelector(".cart-as-sidebar");
                if (flyoutBar && !flyoutBar.contains(event.target)) {
                    if (this.sidebarVisible) {
                        this.hideSidebar();
                    }
                }
            });
        },
        showSidebar() {
            this.sidebarVisible = true;
        },
        hideSidebar() {
            this.sidebarVisible = false;
        },

        async getProfile() {
            try {
                await this.api.client.invoke("readCustomer post /account/customer", {});

                this.isLoggedIn = true;
            } catch (error) {
                this.isLoggedIn = false;
            }
        },

        async getCart() {
            try {
                this.cart = await this.api.client.invoke("readCart get /checkout/cart");
            } catch (error) {
                console.error(error);
            }
        },

        async getPaymentMethods() {
            try {
                const paymentMethods = await this.api.client.invoke("readPaymentMethod post /payment-method");

                this.paymentMethods = paymentMethods.elements.filter(item => item.mediaId !== null);
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>
