import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function all_world_map_blocks_init() {
      $('.js-content-block_world_map').each(function() {
          var $block_content = $(this);
          block_init($block_content);
      });
  }

  function block_init($block_content) {
      var $markers = $block_content.find('.globe-map .globe-map-marker');
      console.log($markers);

      $markers.on('click', function(event) {
          marker_click($(this));
      });

      function marker_click($marker) {
          if($marker.hasClass('active')) {
              return;
          }

          cleanUp();

          $marker.addClass('active');
      }

      function cleanUp() {
          var $active_marker = $block_content.find('.globe-map .globe-map-marker.active');
          $active_marker.removeClass('active');
      }

      regions_nav_init($block_content);
      regions_select_init($block_content);
  }

  function regions_nav_init($block_content) {
      var $links = $block_content.find('.regions-nav').find('a');

      $links.on('click', function(event) {
          event.preventDefault();
          link_click($(this));
      });

      function link_click($link) {
          $links.removeClass('active');
          $link.addClass('active');
          activate_region($block_content, $block_content.find($link.attr('href')));
      }
  }

  function regions_select_init($block_content) {
      var $select = $block_content.find('.regions-select').find('select');

      $select.on('change', function(event) {
          event.preventDefault();
          activate_region($block_content, $block_content.find($select.val()));
      });
  }

  function activate_region($block_content, $region) {
      var $all_regions = $block_content.find('.region');
      $all_regions.removeClass('active');

      var $region_markers = $region.find('.globe-map .globe-map-marker');
      $region_markers.addClass('prepared');
      $region_markers.removeClass('delay');
      $region_markers.removeClass('active');

      $region.removeClass('in-que');
      $region.removeClass('animated');
      $region.addClass('active');


      window.__scrollManager();
  }

  all_world_map_blocks_init();
});
