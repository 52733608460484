import $ from 'jquery'
window.jQuery = window.$ = $

$(function () {
    var $sticky_nav = null;

    var initiated = false;
    var scrollTop = null;
    var direction = null;

    var threshold = 150; //minimum scroll distance

    function sticky_nav_init() {
        $sticky_nav = $('.sticky-nav-wrapper');

        if (!$sticky_nav || !$sticky_nav.length) {
            return;
        }

        $(window).on('scroll', function (event) {

            var newScrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;

            if (newScrollTop >= threshold) {
                if (scrollTop && newScrollTop && scrollTop !== newScrollTop) {
                    if (newScrollTop > scrollTop) {
                        change_direction(1);
                    } else {
                        change_direction(-1);
                    }
                }
            } else {
                change_direction(null);
            }

            scrollTop = newScrollTop;
        });
    }

    function change_direction(dir) {
        if ($sticky_nav && direction !== dir) {
            direction = dir;

            if (direction == -1) {
                // console.log('moving up');
                $sticky_nav.addClass('shown');
                $sticky_nav.removeClass('under-threshold');
            } else if (direction == 1) {
                // console.log('moving down');
                $sticky_nav.removeClass('shown');
                $sticky_nav.removeClass('under-threshold');
            } else {
                // console.log('do nothing, hide');
                $sticky_nav.removeClass('shown');
                $sticky_nav.addClass('under-threshold');
            }
        }
    }

    function create_sticky_nav() {
        var $nav_stacked_wrapper = $('#nav_stacked');
        var $nav_hor_wrapper = $('#nav_hor');

        if (
            $nav_stacked_wrapper
            && $nav_stacked_wrapper.length
            && $nav_stacked_wrapper.hasClass('make-sticky')
        ) {
            var $top_nav = $('.navigation.top-navigation');
            var $main_nav = $('.navigation.main-navigation');

            // Accessability improvements - ARIA id should be unique
            let $top_nav_clone = $top_nav.clone();
            $top_nav_clone.find(".nav-link[id^='dropdown']").map(($k, $v) => $v.id = `${$v.id}-mobile`);
            $top_nav_clone.find(".dropdown-menu[aria-labelledby^='dropdown']").map(($k, $v) => {
                $($v).attr('aria-labelledby', $($v).attr('aria-labelledby') + '-mobile');
            });

            let $main_nav_clone = $main_nav.clone();

            $sticky_nav.append($top_nav_clone);
            $sticky_nav.append($main_nav_clone);
        }
        else
        if (
            $nav_hor_wrapper
            && $nav_hor_wrapper.length
            && $nav_hor_wrapper.hasClass('make-sticky')
        ) {
            var $hor_nav = $('.navigation.hor-navigation');
            $sticky_nav.append($hor_nav.clone());
        }

        // Always make the sticky nav light
        $sticky_nav.find('.navbar-dark').removeClass('navbar-dark').addClass('navbar-light');
        // Always make buttons primary
        $sticky_nav.find('.btn-white').removeClass('btn-white').addClass('btn-primary');
        $sticky_nav.find('.btn-light-on-dark').removeClass('btn-light-on-dark').addClass('btn-primary');

        initiated = true;
    }

    sticky_nav_init();
    setTimeout(create_sticky_nav);
});
