import $ from 'jquery'
window.jQuery = window.$ = $
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

$(function() {
  function getChartData(data) {
    var __labels = String(data.chart_labels).split(',');

    var __datasets = $(data.datasets_pie_chart).map(function(index, item) {
      var __data = $(item.set_data).map(function(_index, _item) {
        return _item.item_value;
      }).toArray();

      var __backgroundColor = $(item.set_data).map(function(_index, _item) {
        return _item.item_color;
      }).toArray();

      var mapped = {
        label: item.label,
        data: __data,
        backgroundColor: __backgroundColor,
        datalabels: {
          display: item.set_inline_datalabels
        }
      };

      return mapped;
    }).toArray();

    return {
      labels: __labels,
      datasets: __datasets
    }
  }

  function getChartOptions(data) {
    var options = {
      maintainAspectRatio: false,
      borderRadius: 10,
      plugins: {
        datalabels: {
          color: 'white', //color: 'grey',
          // backgroundColor: 'white',
          // borderRadius: 4,
          // borderWidth: 1,
          // borderColor: '#e8ebee',
          align: 'center',
          // padding: 8,
          font: {
            size: 16,
            weight: 600
          }
        },
        tooltip: {
          // Config - Start
          enabled: data.tooltip_enabled,
          // Config - Stop

          backgroundColor: 'white',

          titleColor: 'black',
          bodyColor: 'black',
          footerColor: 'black',

          borderColor: 'rgba(0,0,0,0.1)',
          borderWidth: 1,
          padding: 16,

          callbacks: {
            title: function(ttItem) {
              if(ttItem[0].dataset.label) {
                return ttItem[0].dataset.label;
              } else {
                return null;
              }
            }
          }
        },
        legend: {
          // Config - Start
          display: data.show_legend
          // Config - Stop
        },
      }
    }

    return options;
  }



  function buildPieChart(canvas) {
    const data = $(canvas).data('data');

    let chartConfig = {
      type: 'doughnut',
      data: getChartData(data),
      options: getChartOptions(data)
    }

    const chart = new Chart(canvas, chartConfig);
  }

  window.__buildPieChart = buildPieChart;

  // $('canvas.js-chart-type-pie').each(function() {
  //   buildPieChart(this);
  // });
});
