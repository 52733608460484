<template>
    <div>
        <div v-if="isLoading" class="text-center">
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>

        <div
            class="form-check"
            v-if="!isLoading"
            v-for="shippingMethod in shippingMethods"
            :key="shippingMethod.id"
            @change="updateShippingMethod(shippingMethod.id)"
        >
            <input
                class="form-check-input"
                type="radio"
                name="delivery"
                :id="shippingMethod.id"
                v-model="selectedShippingMethodId"
                :value="shippingMethod.id"
            />
            <label class="form-check-label" :for="shippingMethod.id">
                <strong>{{ shippingMethod.translated?.name }}</strong>
                <span class="small ms-0" v-if="shippingMethod.translated?.description">
                    {{ shippingMethod.translated?.description }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>

import shopware from '../../mixins/shopware';
import { EventBus } from '../../api/eventBus.ts';

export default {
    mixins: [shopware],
    props: {
        cart: Object|null
    },
    data() {
        return {
            shippingMethods: [],
            isLoading: false,
            selectedShippingMethodId: null
        };
    },
    watch: {
        'cart.price.totalPrice': function(newVal) {
            this.init();
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        async init() {
            this.isLoading = true;

            await this.getShippingMethods();

            // Set the default shipping method based on the cart data, otherwise default to dealer pickup or the first available one
            if (this.cart && this.cart.deliveries[0]) {
                const cartShippingMethod = this.cart.deliveries[0].shippingMethod.id;

                if (this.shippingMethods.find((method) => method.id === cartShippingMethod)) {
                    this.selectedShippingMethodId = cartShippingMethod;
                } else {
                    this.selectedShippingMethodId = this.shippingMethods[0].id;
                    this.updateShippingMethod(this.selectedShippingMethodId);
                }
            }

            this.isLoading = false;
        },

        async getShippingMethods() {
            const shippingMethods = await this.api.client.invoke("readShippingMethod post /shipping-method?onlyAvailable", {
                onlyAvailable: true
            });
            this.shippingMethods = shippingMethods.elements;
        },

        async updateShippingMethod(shippingMethodId) {
            try {
                EventBus.$emit('updating-shipping-method', true);

                await this.api.client.invoke("updateContext patch /context", {
                    shippingMethodId: shippingMethodId,
                });
            } finally {
                EventBus.$emit('updating-shipping-method', false);
            }
        }
    },
};
</script>
