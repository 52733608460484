import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_readables_items_carousels_init() {
        $('.readables-items-carousel').each(function() {
            carousel_init($(this));
        });
    }

    function carousel_init($carousel) {
        var $slider = null;

        var options = createObjectFromString($carousel.data('options') ?? '');

        function defineOptionValue(propertyName, defaultValue) {
            if(options && options.hasOwnProperty(propertyName)) {
                return options[propertyName];
            } else {
                return defaultValue;
            }
        }

        function slider_create() {
            var config = {
                swipe: defineOptionValue('swipe', true),
                dots: defineOptionValue('dots', true),
                arrows: defineOptionValue('arrows', true),
                infinite: defineOptionValue('infinite', false),
                slidesToShow: defineOptionValue('slidesToShow', 1.1)
            };

            $slider = $carousel.slick(config);
            window.fixSlickOverflow($slider);
        }

        function slider_destroy() {
            if($slider) {
                $slider.slick('unslick');
            }
        }


        var mobileMql = window.matchMedia('(max-width: 767px)');

        if(mobileMql.matches) {
            slider_create();
        }

        mobileMql.addEventListener('change', function(event) {
            if(mobileMql.matches) {
                slider_create();
            } else {
                slider_destroy();
            }
        });
    }

    all_readables_items_carousels_init();
});
