import $ from 'jquery'
window.jQuery = window.$ = $

var $quote_carousel_sliders = $('.js-quote_carousel-slider');

$(function () {

    function quote_carousel_sliders_init() {
        if ($quote_carousel_sliders && $quote_carousel_sliders.length) {
            $quote_carousel_sliders.each(function () {
                quote_carousel_slider_init($(this));
            });
        }
    }

    function quote_carousel_slider_init($slider) {
        var $slides = $slider.find('.slides');

        $slides.slick({
            swipe: true,
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 1360,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });

        $slides.on('setPosition', function () {
            $(this).find('.slick-slide').height('auto');
            var slickTrack = $(this).find('.slick-track');
            var slickTrackHeight = $(slickTrack).height();
            $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
    }

    quote_carousel_sliders_init();
});
