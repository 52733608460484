<template>
    <div>
        <div class="alert alert-danger mb-3 text-center" role="alert" v-if="showError">
            Something went wrong. Please try again later.
        </div>

        <div class="card checkout-card checkout-card-payment-totals" v-if="!showError">
            <div class="card-body">
                <h3 class="mb-3">{{ $t('checkout.payment_total_amount') }}</h3>

                <div class="label-value-row">
                    <span class="label">{{ $t('checkout.payment_total') }}</span>
                    <span v-if="!updatingQuantityItems" class="value fw-bold">&euro;{{ formatNumber(positionPrice) }}</span>
                    <span v-if="updatingQuantityItems" class="spinner-border spinner-border" role="status"aria-hidden="true"></span>
                </div>

                <div class="label-value-row">
                    <span class="label">{{ $t('checkout.payment_delivery_cost') }}</span>
                    <span v-if="!updatingShippingMethod && !hasShippingCosts" class="value fw-bold">
                        -
                    </span>

                    <span v-if="!updatingShippingMethod && hasShippingCosts" class="value fw-bold">
                        &euro;{{ formatNumber(shippingCosts) }}
                    </span>


                    <span v-if="updatingShippingMethod" class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                </div>

                <hr />

                <div class="label-value-row">
                    <span class="label">{{ $t('checkout.payment_final_total') }}</span>
                    <span v-if="!updatingQuantityItems && !updatingShippingMethod" class="value fw-bold">
                        &euro;{{ formatNumber(totalPrice) }}
                    </span>
                    <span v-if="updatingQuantityItems || updatingShippingMethod" class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shopware from '../../mixins/shopware';
import formatters from '../../mixins/formatters';
import { EventBus } from '../../api/eventBus.ts';

export default {
    name: 'CartSummary',
    mixins: [shopware, formatters],
    props: {
        cart: Object|null,
        redirect: String
    },
    data() {
        return {
            internalCart: null,
            updatingQuantityItems: false,
            updatingShippingMethod: false,
            positionPrice: null,
            shippingCosts: null,
            totalPrice: null,
            showError: false
        }
    },
    created: function () {
        this.initialise();
        EventBus.$on('updating-quantity-items', this.updateQuantityItems);
        EventBus.$on('updating-shipping-method', this.updateShippingMethod);
    },
    beforeDestroy() {
        EventBus.$off('updating-quantity-items', this.updateQuantityItems);
        EventBus.$off('updating-shipping-method', this.updateShippingMethod);
    },
    computed: {
        cartData() {
            return this.internalCart || this.cart;
        },
        hasShippingCosts() {
            return this.shippingCosts > 0;
        }
    },
    methods: {
        initialise() {
            if (!this.cart) {
                this.fetchCart();
            }else{
                this.constructSummary();
            }
        },
        async fetchCart() {
            try {
                this.internalCart = await this.api.client.invoke("readCart get /checkout/cart");
                this.constructSummary();
            } catch (error) {
                console.error("Error fetching cart:", error);
            }
        },
        async constructSummary() {
            try {
                this.positionPrice = this.cartData?.price?.positionPrice;
                this.shippingCosts = this.cartData?.deliveries[0].shippingCosts?.totalPrice;
                this.totalPrice = this.cartData?.price?.totalPrice;
            } catch (error) {
                this.showError = true;
                if (this.redirect) {
                    window.location.href = this.redirect;
                    return;
                }
            } finally{
                this.updatingQuantityItems = false;
                this.updatingShippingMethod = false;
            }
        },
        updateQuantityItems(status) {
            //Update cart when updating items ended instead of status change
            if(this.updatingQuantityItems && !status){
                this.fetchCart();
                return;
            }

            this.updatingQuantityItems = status
        },
        updateShippingMethod(status) {
            //Update cart when updating shipping method ended instead of status change
            if(this.updatingShippingMethod && !status){
                this.fetchCart();
                return;
            }

            this.updatingShippingMethod = status
        },
    }
};
</script>
