document.addEventListener('DOMContentLoaded', function() {
    function allFaqsOverviewBlocksInit() {
        const blocks = document.querySelectorAll('.content-block_faqs_overview');
        blocks.forEach((block) => {
            blockInit(block);
        });
    }

    function blockInit(blockContent) {
        const sections = blockContent.querySelectorAll('.section');
        const links = blockContent.querySelectorAll('.card a');

        let currentSection = null;

        function scrollManager() {
            const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            let newSection = 0;

            sections.forEach((el, i) => {
                const distance = offset(el).top - scrollTop;
                const threshold = 200;

                if (distance < threshold) {
                    newSection = i;
                }
            });

            if (currentSection !== newSection) {
                currentSection = newSection;

                links.forEach((link) => {
                    link.classList.remove('active');
                });

                if (links[currentSection]) {
                    links[currentSection].classList.add('active');
                }
            }
        }

        scrollManager();

        window.addEventListener('scroll', function() {
            scrollManager();
        });
    }

    function offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    allFaqsOverviewBlocksInit();
});
