import $ from 'jquery'
window.jQuery = window.$ = $
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

$(function() {
  function getChartData(data) {
    var __labels = String(data.chart_labels).split(',');

    var __datasets = $(data.datasets_xy_chart).map(function(index, item) {
      var __data = $(item.set_data).map(function(index, item) {
        return item.item_value;
      }).toArray();

      var __backgroundColor = [];
      if(item.colors_mode.value === "single_color") {
        __backgroundColor.push(item.set_color);
      } else if(item.colors_mode.value === "color_per_bar") {
        __backgroundColor = $(item.bar_colors).map(function(index, item) {
          return item.bar_color;
        }).toArray();
      }

      var mapped = {
        label: item.set_label,
        data: __data,
        type: item.set_type.value,
        backgroundColor: __backgroundColor,
        datalabels: {
          display: item.set_inline_datalabels
        }
      };

      return mapped;
    }).toArray();

    return {
      labels: __labels,
      datasets: __datasets
    }
  }

  function getChartOptions(data) {
    var options = {
      maintainAspectRatio: false,
      // Config - Start
      indexAxis: data?.index_axis?.value || 'x',
      // Config - End
      tension: 0.3,
      borderRadius: 6,
      radius: 6,
      barPercentage: (data?.bar_percentage || 80)/100,
      scales: {
          x: {
            // Config - Start
            display: data.show_x_axis,
            // Config - Stop
            grid: {
              display: false,
            }
          },
          y: {
            // Config - Start
            display: data.show_y_axis,
            // Config - Stop
            grid:  {
              display: true,
              lineWidth: 1,
              color: 'rgba(0,0,0,0.1)'
            }
          }
      },
      plugins: {
        tooltip: {
          // Config - Start
          enabled: data.tooltip_enabled,
          // Config - Stop

          backgroundColor: 'white',

          titleColor: 'black',
          bodyColor: 'black',
          footerColor: 'black',

          borderColor: 'rgba(0,0,0,0.1)',
          borderWidth: 1,
          padding: 16
        },
        legend: {
          // Config - Start
          display: data.show_legend
          // Config - Stop
        }
      },
      elements: {
        line: {
          borderColor: 'rgba(0,0,0,0.25)'
        }
      }
    }

    // Config - Start
    options.scales[options.indexAxis=='x' ? 'y' : 'x'].min = data.min_value;
    options.scales[options.indexAxis=='x' ? 'y' : 'x'].max = data.max_value;
    // Config - Stop


    if(options.indexAxis=='x') {
      options.plugins.datalabels = {
        color: 'grey',
        backgroundColor: 'white',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#e8ebee',
        offset: 8,
        align: 'top',
        padding: 8,
        font: {
          size: 16,
          weight: 600
        }
      }
    } else {
      options.scales.y.grid.lineWidth = 0;

      options.plugins.datalabels = {
        color: 'white',
        align: 'center',
        font: {
          size: 16,
          weight: 600
        }
      }
    }


    return options;
  }

  function buildXyChart(canvas) {
    const data = $(canvas).data('data');

    let chartConfig = {
      data: getChartData(data),
      options: getChartOptions(data)
    }

    const chart = new Chart(canvas, chartConfig);
  }

  window.__buildXyChart = buildXyChart;

  // $('canvas.js-chart-type-xy').each(function() {
  //   buildXyChart(this);
  // });
});
