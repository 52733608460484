import Plyr from 'plyr';
import $ from 'jquery'
window.jQuery = window.$ = $

// https://github.com/sampotts/plyr/#options

$(function() {
    function videos_init() {
        const players = Array.from(document.querySelectorAll('.js-plyr-player-video')).map((p) => {
            var options = {};
            var isAutoplay = p.hasAttribute('autoplay');

            var controls = [
                'play-large',
                'play',
                'progress',
                'fullscreen',
                'autoplay',
            ];

            if(isAutoplay) {
                options.muted = true;
                options.autoplay = true;
                options.volume = 0;
            } else {
                options.volume = 1;
                options.muted = false;
                controls.push('volume');
                controls.push('mute');
            }

            options.controls = controls;

            var player = new Plyr(p, options);
            playerOnReady(player, isAutoplay);

            return player;
        });
    }

    function playerOnReady(player, autoplay = false) {
        player.on('ready', function(event) {
            if(autoplay) {
                player.muted = true;
                player.play();
            } else {
                player.volume = 1;
            }
        });
    }

    videos_init();
});
