<template>
    <div class="row g-5 g-xl-3 justify-content-between">
        <div v-if="isLoading" class="text-center">
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>

        <div v-if="!isLoading" class="column col-12 col-xl-7">
            <h5 class="mb-2">{{ $t("checkout.payment_method") }}</h5>
            <p>{{ $t("checkout.confirm_continue_payment_text") }}</p>

            <payment-selector :cart="cart"></payment-selector>

            <hr class="my-4" />

            <div class="row g-4">
                <div class="col-12 col-md-6 d-flex flex-column align-items-start">
                    <h5 class="mb-2">{{ $t('checkout.confirm_continue_your_info') }}</h5>

                    <p>
                        {{ this.profile?.activeBillingAddress?.firstName }} {{
                            this.profile?.activeBillingAddress?.lastName
                        }}<br />
                        {{ this.profile?.activeBillingAddress?.street }}<br />
                        {{ this.profile?.activeBillingAddress?.zipcode }} {{ this.profile?.activeBillingAddress?.city
                        }}<br />
                        {{ country?.translated?.name }}
                    </p>

                    <p>{{ email }}</p>

                    <a href="#" class="icon-link mt-auto" data-bs-toggle="modal" data-bs-target="#modalEditAddressInfo">
                        <PhPencilSimple weight="bold" />
                        <span>{{ $t('checkout.btn_edit_info') }}</span>
                    </a>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column align-items-start">
                    <h5 class="mb-2">{{ $t('checkout.confirm_continue_delivery') }}</h5>

                    <template v-if="deliveryMethod === 'DEALER'">
                        <p class="small mb-1">{{ $t('checkout.confirm_continue_free_pickup_text') }}</p>

                        <p class="h5 text-dark">{{ cart?.extensions?.dealer?.translated.name }}</p>
                        <p>{{ cart?.extensions.dealer.address }}, {{ cart?.extensions.dealer.zipcode }} {{
                            cart?.extensions.dealer.city }}</p>

                        <a :href="dealerSelectRoute" class="icon-link mt-auto">
                            <PhPencilSimple weight="bold" />
                            <span>{{ $t('checkout.btn_edit_dealer') }}</span>
                        </a>
                    </template>
                    <template v-if="deliveryMethod === 'DELIVERY'">
                        <p class="small"
                            v-if="this.profile?.activeShippingAddress?.id === this.profile?.activeBillingAddress?.id">
                            {{ $t('checkout.confirm_continue_home_delivery_text') }}
                        </p>

                        <template
                            v-if="this.profile?.activeShippingAddress?.id !== this.profile?.activeBillingAddress?.id">
                            <p class="small mb-1">{{
                                $t('checkout.confirm_continue_home_delivery_different_address_text') }}</p>

                            <p>
                                {{ this.profile?.activeShippingAddress?.firstName }} {{
                                    this.profile?.activeShippingAddress?.lastName }}<br />
                                {{ this.profile?.activeShippingAddress?.street }}<br />
                                {{ this.profile?.activeShippingAddress?.zipcode }} {{
                                    this.profile?.activeShippingAddress?.city
                                }}<br />
                                {{ deliveryCountry?.translated?.name }}
                            </p>
                        </template>

                        <a href="#" class="icon-link mt-auto" data-bs-toggle="modal" data-bs-target="#modalEditShippingInfo">
                            <PhPencilSimple weight="bold" />
                            <span>{{ $t('checkout.btn_edit_delivery') }}</span>
                        </a>
                    </template>
                </div>
            </div>

            <hr class="my-4" />

            <h5 class="mb-2">
                {{ $t("checkout.confirm_continue_products_in_order") }}
            </h5>

            <cart-line-items :cart="cart" @update-cart="updateCart"></cart-line-items>
        </div>

        <div v-if="!isLoading" class="column col-12 col-xl-4">
            <div class="sticky-top">
                <cart-summary :cart="cart" :redirect="failureRedirect" />

                <button v-on:click="proceedToPayment" class="btn btn-secondary w-100 mt-1 position-relative">
                    <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isCreatingOrder }">
                        <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                    </div>

                    <div class="d-flex align-items-center" :class="{ 'opacity-0': isCreatingOrder }">
                        <p class="mb-0">
                            {{ $t("checkout.btn_confirm_and_pay") }}
                        </p>
                    </div>
                </button>
            </div>
        </div>

        <!-- MODALS -->

        <!-- modalEditAddressInfo -->
        <div class="modal fade" id="modalEditAddressInfo" tabindex="-1" aria-labelledby="modalEditAddressInfoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditAddressInfoLabel">{{ $t('account.address_info_edit') }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form @submit.prevent="updateBillingAddressInfo" id="addressInfoForm">
                                <CustomerAddress
                                    :firstName.sync="firstName"
                                    :lastName.sync="lastName"
                                    :street.sync="street"
                                    :zipcode.sync="zipcode"
                                    :city.sync="city"
                                    :countryId.sync="countryId"
                                    :countries="countries"
                                    :showNameFields="true"
                                />
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="submit" form="addressInfoForm" class="btn btn-primary position-relative">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isUpdatingAddressInfo }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingAddressInfo }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END modalEditAddressInfo -->

        <!-- modalEditShippingInfo -->
        <div class="modal fade" id="modalEditShippingInfo" tabindex="-1" aria-labelledby="modalEditShippingInfoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditShippingInfoLabel">{{ $t('account.address_info_edit') }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form @submit.prevent="updateShippingAddressInfo" id="addressShippingInfoForm">
                                <CustomerAddress
                                    :firstName.sync="deliveryFirstName"
                                    :lastName.sync="deliveryLastName"
                                    :street.sync="deliveryStreet"
                                    :zipcode.sync="deliveryZipcode"
                                    :city.sync="deliveryCity"
                                    :countryId.sync="deliveryCountryId"
                                    :countries="countries"
                                    :showNameFields="true"
                                />
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="submit" form="addressShippingInfoForm" class="btn btn-primary position-relative">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isUpdatingAddressInfo }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingAddressInfo }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END modalEditShippingInfo -->

    </div>
</template>

<script>
import CartLineItems from './CartLineItems.vue';
import ShipmentSelector from './ShipmentSelector.vue';
import CartSummary from './CartSummary.vue';

import shopware from '../../mixins/shopware';
import customer from '../../mixins/customer';

import { EventBus } from '../../api/eventBus.ts';
import { PhPencilSimple } from 'phosphor-vue';
import { Modal } from 'bootstrap';

export default {
    components: {
        CartLineItems,
        ShipmentSelector,
        CartSummary,
        PhPencilSimple,
    },
    mixins: [shopware, customer],
    props: {
        dealerSelectRoute: String,
        failureRedirect: String,
        paymentFailureRedirect: String,
        paymentSuccessRedirect: String,
    },
    data() {
        return {
            cart: null,
            order: null,
            error: null,
            profile: {},
            isLoading: false,
            isLoggedIn: false,

            // modals
            addressInfoModal: null,
            shippingInfoModal: null,

            // loaders
            isUpdatingPersonalInfo: false,
            isUpdatingAddressInfo: false,
            isCreatingOrder: false,

            // data
            salutationId: null,
            salutation: null,
            firstName: '',
            lastName: '',
            email: '',
            street: '',
            zipcode: '',
            city: '',
            countryId: null,
            country: '',
            deliveryFirstName: '',
            deliveryLastName: '',
            deliveryStreet: '',
            deliveryZipcode: '',
            deliveryCity: '',
            deliveryCountryId: null,
            deliveryCountry: ''
        };
    },
    created: function () {
        this.init();
    },
    mounted: function () {
        this.addressInfoModal = new Modal(document.getElementById('modalEditAddressInfo'));
        this.shippingInfoModal = new Modal(document.getElementById('modalEditShippingInfo'));
    },
    computed: {
        deliveryMethod() {
            if (this.cart && this.cart?.extensions?.dealer && this.cart?.deliveries[0].shippingMethod?.technicalName === 'shipping_dealer') {
                return 'DEALER';
            } else {
                return 'DELIVERY';
            }
        }
    },
    methods: {
        async init() {
            this.isLoading = true;

            await this.getContext();

            const promises = [
                this.getCart(),
                this.getCountries(),
            ];

            await Promise.all(promises);

            this.initProfile();
            this.isLoading = false;
        },

        async initProfile() {
            this.profile = this.context.customer;

            if (this.profile) {
                this.salutationId = this.profile.salutationId;
                this.firstName = this.profile?.firstName;
                this.lastName = this.profile?.lastName;
                this.email = this.profile.email;

                if (this.profile.activeBillingAddress.id) {
                    this.lastName = this.profile?.activeBillingAddress?.lastName;
                    this.firstName = this.profile?.activeBillingAddress?.firstName;
                    this.street = this.profile?.activeBillingAddress?.street;
                    this.addressNumber = this.profile?.activeBillingAddress?.addressNumber;
                    this.addressSupplement = this.profile?.activeBillingAddress?.addressSupplement;
                    this.zipcode = this.profile?.activeBillingAddress?.zipcode;
                    this.city = this.profile?.activeBillingAddress?.city;
                    this.countryId = this.profile?.activeBillingAddress?.countryId;
                    this.country = this.profile?.activeBillingAddress?.country;
                }

                if (this.profile.activeShippingAddress.id) {
                    this.deliveryDifferentAsBilling = true;
                    this.deliveryFirstName = this.profile?.activeShippingAddress?.firstName;
                    this.deliveryLastName = this.profile?.activeShippingAddress?.lastName;
                    this.deliveryStreet = this.profile?.activeShippingAddress?.street;
                    this.deliveryAddressNumber = this.profile?.activeShippingAddress?.addressNumber;
                    this.deliveryAddressSupplement = this.profile?.activeShippingAddress?.addressSupplement;
                    this.deliveryZipcode = this.profile?.activeShippingAddress?.zipcode;
                    this.deliveryCity = this.profile?.activeShippingAddress?.city;
                    this.deliveryCountryId = this.profile?.activeShippingAddress?.countryId;
                    this.deliveryCountry = this.profile?.activeShippingAddress?.country;
                }

                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        },

        async getCart() {
            try {
                if (localStorage.getItem("lastOrderId") !== null) {
                    await this.restoreCart();
                } else {
                    this.cart = await this.api.client.invoke("readCart get /checkout/cart");
                }
            } catch (error) {
                console.error(error);
            }
        },

        async restoreCart() {
            this.isLoading = true;
            this.error = null;

            try {
                // Get the last order ID from local storage or vuex store
                const failedOrderId = localStorage.getItem("lastOrderId");

                if (!failedOrderId) {
                    throw new Error("No failed order ID found");
                }

                // Fetch the failed order
                const response = await this.api.client.invoke("readOrder post /order", {
                    "limit": 1,
                    "ids": [failedOrderId],
                    "associations": {
                        "lineItems": {},
                    }
                });

                const failedOrder = response.orders.elements[0];

                // Create a new cart
                this.cart = await this.api.client.invoke("readCart post /checkout/cart");

                // Add items from the failed order to the new cart
                for (const lineItem of failedOrder.lineItems) {
                    await this.api.client.invoke("addLineItem post /checkout/cart/line-item", {
                        items: [
                            {
                                id: lineItem.id,
                                modified: lineItem.modified,
                                type: lineItem.type,
                                referencedId: lineItem.referencedId,
                                quantity: lineItem.quantity,
                                good: lineItem.good,
                                payload: {
                                    productUrl: lineItem.payload.productUrl,
                                    description: lineItem.payload.description,
                                    variant: lineItem.payload.variant,
                                    coverImg: lineItem.payload.coverImg
                                }

                            }
                        ]
                    });
                }

                // Add dealer
                await this.api.client.invoke(`dealerToCart patch /checkout/cart/dealer`, {
                    token: this.context.token,
                    customer: this.context.customer.id,
                    dealer: failedOrder.customFields?.dealer
                });

                localStorage.removeItem("lastOrderId");
            } catch (err) {
                this.error = `Failed to restore cart: ${err.message}`;

                EventBus.$emit("trigger-alert", {
                    message: this.error,
                    state: "error",
                });
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        updateCart(updatedCart) {
            this.cart = updatedCart;
        },

        async updateBillingAddressInfo() {
            if (this.firstName === this.profile?.activeBillingAddress?.firstName &&
                this.lastName === this.profile?.activeBillingAddress?.lastName &&
                this.street === this.profile?.activeBillingAddress?.street &&
                this.zipcode === this.profile?.activeBillingAddress?.zipcode &&
                this.city === this.profile?.activeBillingAddress?.city &&
                this.countryId === this.profile?.activeBillingAddress?.countryId
            ) {
                this.addressInfoModal.hide();
                return;
            }

            const data = {
                street: this.street,
                zipcode: this.zipcode,
                city: this.city,
                countryId: this.countryId,
                firstName: this.firstName,
                lastName: this.lastName
            };

            await this.changeAddress(null, this.profile?.activeBillingAddress?.id, data);

            this.addressInfoModal.hide();
        },

        async updateShippingAddressInfo() {
            if (this.deliveryFirstName === this.profile.activeShippingAddress.firstName &&
                this.deliveryLastName === this.profile.activeShippingAddress.lastName &&
                this.deliveryStreet === this.profile.activeShippingAddress.street &&
                this.deliveryZipcode === this.profile.activeShippingAddress.zipcode &&
                this.deliveryCity === this.profile.activeShippingAddress.city &&
                this.deliveryCountryId === this.profile.activeShippingAddress.countryId
            ) {
                this.shippingInfoModal.hide();
                return;
            }

            const data = {
                street: this.deliveryStreet,
                zipcode: this.deliveryZipcode,
                city: this.deliveryCity,
                countryId: this.deliveryCountryId,
                firstName: this.deliveryFirstName,
                lastName: this.deliveryLastName
            };

            const shippingAddressId = this.findShippingSpecificAddressId(this.profile);

            if (shippingAddressId) {
                // update existing address
                await this.changeAddress(null, shippingAddressId, data, 'shipping');
            } else {
                // create new address + set as default
                await this.changeAddress(this.profile.id, null, data, 'shipping');
            }

            this.shippingInfoModal.hide();
        },

        async changeAddress(customerId = null, addressId = null, data, setDefault = null) {
            if (!customerId && !addressId) {
                return;
            }

            try {
                this.isUpdatingAddressInfo = true;

                if (addressId) {
                    await this.updateCustomerAddress(addressId, data, setDefault);
                }

                if (customerId) {
                    await this.createCustomerAddress(customerId, data, setDefault);
                }

                await this.getContext();
                this.initProfile();

                EventBus.$emit('trigger-alert', {
                    message: this.$t('messages.changed_address'),
                    state: 'success'
                });
            } finally {
                this.isUpdatingAddressInfo = false;
            }
        },

        async proceedToPayment() {
            try {
                this.isCreatingOrder = true;

                // Step 1: Create order from cart
                this.order = await this.api.client.invoke("createOrder post /checkout/order");

                // Step 2: Store the order ID in localStorage
                const orderId = this.order.id;
                localStorage.setItem("lastOrderId", orderId);

                // Step 3: Handle Payment
                const payment = await this.api.client.invoke(
                    "handlePayment post /handle-payment",
                    {
                        orderId: orderId,
                        finishUrl: this.paymentSuccessRedirect,
                        errorUrl: this.paymentFailureRedirect,
                    }
                );

                // Step 4: Reset paymentmethod to default once completed
                await this.api.client.invoke("updateContext patch /context", {
                    paymentMethodId: this.context.salesChannel.paymentMethodId
                });

                window.location.href = payment.redirectUrl || this.paymentSuccessRedirect;
            } catch (error) {
                EventBus.$emit("trigger-alert", {
                    message: this.$t("error.generic"),
                    state: "error",
                });
            } finally {
                this.isCreatingOrder = false;
            }
        }
    }
};
</script>
