import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_mobile_items_carousels_init() {
        $('.mobile-items-carousel').each(function() {
            carousel_init($(this));
        });
    }

    function carousel_init($carousel) {
        var $slider = null;

        var options = createObjectFromString($carousel.data('options') ?? '');


        function defineOptionValue(propertyName, defaultValue) {
            if(options && options.hasOwnProperty(propertyName)) {
                return options[propertyName];
            } else {
                return defaultValue;
            }
        }

        function slider_create() {
            console.log("slider_create");

            var config = {
                swipe: defineOptionValue('swipe', true),
                dots: defineOptionValue('dots', true),
                arrows: defineOptionValue('arrows', true),
                infinite: defineOptionValue('infinite', false),
                slidesToShow: defineOptionValue('slidesToShow', 4),
                slidesToScroll: defineOptionValue('slidesToShow', 4),
                responsive: [
                    {
                        breakpoint: 1359,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-lg', 3.1),
                            slidesToScroll: defineOptionValue('slidesToShow-lg', 3),
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-md', 2.1),
                            slidesToScroll: defineOptionValue('slidesToShow-md', 2),
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: defineOptionValue('slidesToShow-sm', 1.1),
                            slidesToScroll: defineOptionValue('slidesToShow-sm', 1),
                        }
                    }
                ]

            };

            $slider = $carousel.slick(config);
            window.fixSlickOverflow($slider);

        }

        function slider_destroy() {
            console.log("slider_destroy");

            if($slider) {
                $slider.slick('unslick');
            }
        }

        slider_create();

        // var mobileMql = window.matchMedia('(max-width: 1360px)');

        // if(mobileMql.matches) {
        //     slider_create();
        // }

        // mobileMql.addEventListener('change', function(event) {
        //     console.log("change");

        //     if(mobileMql.matches) {
        //         slider_create();
        //     } else {
        //         slider_destroy();
        //     }
        // });
    }

    all_mobile_items_carousels_init();
});
