<template>
    <div class="col-12" v-if="currentVariantData.ean">
        <a class="cc-fi-button btn btn-secondary btn-lg w-100 position-relative" :id="currentVariantData?.slug || ''" href="#">
            <ph-shopping-cart weight="bold" />
            <p class="mb-0 ms-1">{{ $t('products.add_to_cart') }}</p>
        </a>
    </div>
</template>

<script>
import { EventBus } from "../../api/eventBus.ts";
import { PhShoppingCart } from "phosphor-vue";

export default {
    components: {
        PhShoppingCart
    },
    props: {
        variantJson: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            variants: [],
            activeVariant: null,
        };
    },
    computed: {
        currentVariantData() {
            return this.activeVariant !== null
                ? this.variants.find(variant => variant.id === this.activeVariant)
                : null;
        },
    },
    watch: {
        currentVariantData(newVal) {
            if (newVal) {
                this.loadScript(newVal.ean);
            }
        }
    },
    created() {
        this.variants = JSON.parse(this.variantJson);
        EventBus.$on("variant-selected", this.changeVariant);
    },
    beforeDestroy() {
        EventBus.$off("variant-selected", this.changeVariant);
        this.cleanupElements();
    },
    methods: {
        changeVariant(id) {
            this.activeVariant = id;
        },
        cleanupElements() {
            // Remove the container div
            const container = document.querySelector('[data-ccid="cc-wtb-button-container"]');
            if (container) container.remove();

            // Remove all scripts
            const scriptsJs0 = document.querySelectorAll('script[id^="-js-0"]');
            scriptsJs0.forEach(script => script.remove());

            const scriptsJs1 = document.querySelectorAll('script[id^="-js-1"]');
            scriptsJs1.forEach(script => script.remove());

            const scriptsJs2 = document.querySelectorAll('script[id^="-js-2"]');
            scriptsJs2.forEach(script => script.remove());

            // Remove the cci-widget script
            const cciWidget = document.getElementById('cci-widget');
            if (cciWidget) cciWidget.remove();

            // Remove the cci-root div and its contents
            const cciRoot = document.getElementById('cci-root');
            if (cciRoot) cciRoot.remove();
        },
        loadScript(ean) {
            // Clean up existing elements
            this.cleanupElements();

            // Create new script element
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://fi-v2.global.commerce-connector.com/cc.js';
            script.id = 'cci-widget';
            script.dataset.token = '90ef747fee2d5bfd120b829b70b18e3dcc4c402c';
            script.dataset.locale = 'de-DE';
            script.dataset.displaylanguage = 'de';
            script.dataset.widgetid = 'gRwADMvo4z_033743a0-4d3d-11ec-bbba-13a119b875b118';
            script.dataset.ean = ean;
            script.dataset.subid = 'website';
            script.dataset.trackingid = 'POS-DE-CommerceConnector';
            script.dataset.eventgtm = 'POS-DE-CommerceConnector';

            // Append script to body
            document.body.appendChild(script);
        }
    }
};
</script>
