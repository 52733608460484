export default {
    methods: {
        isVoucher(lineItem) {
            return (lineItem.type == 'promotion' && 'acrisProductVoucherCodes' in lineItem.payload);
        },

        showStockStatus(lineItem) {
            const status = lineItem?.payload?.stockStatus;

            return (status && status !== 'product_in_stock');
        }
    }
};
