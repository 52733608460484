import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_tabbed_contents_init() {
        $('.js-tabbed-content').each(function() {
            var $tabbed_content = $(this);
            tabbed_content_init($tabbed_content);
        });
    }

    function tabbed_content_init($tabbed_content) {

        console.log('$tabbed_content');
        console.log($tabbed_content);

        var $navItems = $tabbed_content.find('.js-tab-nav');
        var $contents = $tabbed_content.find('.js-tab-content');

        console.log('$navItems');
        console.log($navItems);

        function nav_listeners($items) {
            $items.each(function(i, el) {
                var $el = $(el);
                nav_listener($el);
            });
        }

        function nav_listener($item) {
            $item.on('click', function(event) {
                event.preventDefault();

                if($item.hasClass('active')) {
                    return;
                }

                console.log('$contents');
                console.log($contents);

                $contents.addClass('d-none');
                $navItems.removeClass('active');

                var $target = $($item.attr('href'));
                $target.removeClass('d-none');
                $target.css('display', 'none');
                $target.fadeIn(500);

                $item.addClass('active');
            });
        }

        nav_listeners($navItems);
    }

    all_tabbed_contents_init();
});
