import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
    function all_pricing_features_blocks() {
        $('.content-block_pricing_feature_sections').each(function() {
            var $block_content = $(this);
            block_init($block_content);
        });
    }

    function block_init($block) {
        var $toggles = $block.find('.togglable');
        $toggles.on('click', function(e) {
            e.preventDefault();
            toggle_feature($(this));
        });
    }

    function toggle_feature($toggle) {
        var $feature_row = $toggle.closest('.row-feature');
        $feature_row.toggleClass('shown');
    }

    all_pricing_features_blocks();
});
