import $ from 'jquery'
window.jQuery = window.$ = $

$(function () {
    var $steps_carousel_sliders = $('.js-steps_carousel-slider');

    function steps_carousel_sliders_init() {
        if ($steps_carousel_sliders && $steps_carousel_sliders.length) {
            $steps_carousel_sliders.each(function () {
                steps_carousel_slider_init($(this));
            });
        }
    }

    function steps_carousel_slider_init($slider) {
        var $slides = $slider.find('.slides');

        var $steps = $slider.find('.step');
        // var maxSlides = ($steps.length > 3) ? 2.5 : 3;
        var maxSlides = 3;

        $slides.slick({
            swipe: true,
            dots: false,
            infinite: false,
            slidesToShow: maxSlides,
            prevArrow: $slider.find('.custom-arrows .slick-prev'),
            nextArrow: $slider.find('.custom-arrows .slick-next'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                    breakpoint: 1600,
                    settings: {
                      slidesToShow: 3
                    }
                  }
              ]
        });
    }

    steps_carousel_sliders_init();
});
