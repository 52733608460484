import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function content_block_left_right_content() {
      var $lr_content_blocks = $('.content-block_left_right_content');

      if($lr_content_blocks && $lr_content_blocks.length) {
          $lr_content_blocks.each(function() {
              lr_content_block_init($(this));
          });
      }
  }

  function lr_content_block_init($block) {
    var $rows = $block.find('.row-asset-grid-base');

    if($rows && $rows.length) {
      $rows.each(function() {
        var $row = $(this);
        if($row.find('.slick-asset-carousel')) {
          row_with_carousel($row);
        }
      });
    }
  }

  function row_with_carousel($row) {
    var $carousel = $row.find('.slick-asset-carousel');

    var $slick_carousel = $carousel.slick({
      swipe: true,
      dots: true,
      accessibility: false,
      arrows: true,
      infinite: true,
      fade: false,
    //   customPaging : function(slider, i) {
    //     return '<a href="#" class="marker"></a>';
    //   },
      autoplay: true,
      autoplaySpeed: 3000,
    //   prevArrow: $row.find('.slick-asset-arrow.left'),
    //   nextArrow: $row.find('.slick-asset-arrow.right')
    });

    let $dots = $carousel.find('.slick-dots');
    $dots.attr('role', 'presentation');
    $dots.find('.dot').each(($k, $v) => $($v).attr('aria-label', `select slide ${$k +1}`));

  }

  content_block_left_right_content();
});
