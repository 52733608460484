import $ from 'jquery'
window.jQuery = window.$ = $

$(function() {
  function mobile_nav_init() {
    var $mobile_nav = $('.mobile-navigation.navigation');
    var $togggler = $('.navbar-toggler');

    $togggler.on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        $mobile_nav.toggleClass('open');

        $('html').toggleClass('noscroll');
        $('body').toggleClass('noscroll');
    });

    window.matchMedia('(min-width: 992px)').onchange = (e) => {
        if (e.matches) {
            $mobile_nav.removeClass('open');
            $('html').removeClass('noscroll');
            $('body').removeClass('noscroll');
        }
    }

    var $nav_stacked_wrapper = $('#nav_stacked');
    var $nav_hor_wrapper = $('#nav_hor');

    if($nav_stacked_wrapper && $nav_stacked_wrapper.length) {
        var $main_nav = $('.navigation.main-navigation .navbar-nav');
        var $top_nav = $('.navigation.top-navigation .navbar-nav');
        var $nav_buttons = $('.navigation.main-navigation .buttons');
    } else if($nav_hor_wrapper && $nav_hor_wrapper.length) {
        var $main_nav = $('.navigation.hor-navigation .navbar-nav.navbar-nav-main-items');
        var $top_nav = $('.navigation.hor-navigation .navbar-nav.navbar-nav-top-items');
        var $nav_buttons = $('.navigation.hor-navigation .buttons');
    }

    var $subsite_nav = $('.navigation.subsite-navigation .navbar-nav');

    var $space_main_nav = $('.mobile-main-nav');
    var $space_sec_nav = $('.mobile-sec-nav');
    var $space_sub_nav = $('.mobile-sub-nav');

    // Accessability improvements - ARIA id should be unique
    if($main_nav) {
        let $main_nav_clone = $main_nav.clone();
        $main_nav_clone.find(".nav-link[id^='dropdown']").map(($k, $v) => $v.id = `${$v.id}-mobile`);
        $main_nav_clone.find(".dropdown-menu[aria-labelledby^='dropdown']").map(($k, $v) => {
            $($v).attr('aria-labelledby', $($v).attr('aria-labelledby') + '-mobile');
        });

        $main_nav_clone.find('.card-img').remove();
        $main_nav_clone.find('.card .text-mode-white').removeClass('text-mode-white');
        $main_nav_clone.find('.dropdown-products .btn');

        $space_main_nav.find('.container').append($main_nav_clone);

        if($nav_buttons && $nav_buttons.length) {
            var $nav_buttons_clone = $nav_buttons.clone();

            $nav_buttons_clone.find('.btn-sm').removeClass('btn-sm');
            $nav_buttons_clone.find('.btn-light-on-dark').removeClass('btn-light-on-dark').addClass('btn-primary');
            $nav_buttons_clone.find('.btn-light').removeClass('btn-light').addClass('btn-primary');
            $nav_buttons_clone.find('.btn-white').removeClass('btn-white').addClass('btn-primary');

            $space_main_nav.find('.container').append($nav_buttons_clone);
        }

    }

    // Accessability improvements - ARIA id should be unique
    if($top_nav) {
        let $top_nav_clone = $top_nav.clone();
        $top_nav_clone.find(".nav-link[id^='dropdown']").map(($k, $v) => $v.id = `${$v.id}-mobile`);
        $top_nav_clone.find(".dropdown-menu[aria-labelledby^='dropdown']").map(($k, $v) => {
            $($v).attr('aria-labelledby', $($v).attr('aria-labelledby') + '-mobile');
        });

        $space_sec_nav.find('.container').append($top_nav_clone);
        $space_sub_nav.find('.container').append($subsite_nav.clone());
    }
  }
  mobile_nav_init();
});
