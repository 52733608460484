<template>
    <div class="col-6 col-md-auto" v-if="currentVariantData && statusCheck(currentVariantData.status)">
        <div class="h2 m-0 text-end text-md-start">€{{ formatNumber(currentVariantData.price) }}</div>
    </div>
</template>

<script>
import { EventBus } from '../../api/eventBus.ts';
import formatters from '../../mixins/formatters';
import product from '../../mixins/product';

export default {
    mixins: [formatters, product],
    props: {
        variantJson: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            variants: [],
            activeVariant: null,
        };
    },
    computed: {
        currentVariantData() {
            return this.activeVariant !== null
                ? this.variants.find(variant => variant.id === this.activeVariant)
                : null;
        },
    },
    created() {
        // Parse the JSON string into an object
        this.variants = JSON.parse(this.variantJson);

        EventBus.$on("variant-selected", this.changeVariant);
    },
    beforeDestroy() {
        EventBus.$off("variant-selected", this.changeVariant);
    },
    methods: {
        changeVariant(id) {
            this.activeVariant = id;
        }
    }
};
</script>
