<template>
    <button v-if="showButton" @click="addToCart" class="btn btn-primary w-100 btn-lg position-relative">
        <div class="position-absolute top-50 start-50 translate-middle">
            <span class="spinner-border spinner-border-sm" :class="{ 'd-none': !isLoading }" role="status" aria-hidden="true"></span>
        </div>

        <div class="d-flex align-items-center" :class="{ 'opacity-0': isLoading }">
            <ph-shopping-cart weight="bold" />
            <p class="mb-0 ms-1">{{ $t('products.add_to_cart') }}</p>
        </div>
    </button>
</template>

<script lang="ts">
import { Api } from "../../api/apiClient";
import { EventBus } from "../../api/eventBus";
import product from "../../mixins/product";
import { PhShoppingCart } from "phosphor-vue";

export default {
    mixins: [product],
    components: {
        PhShoppingCart
    },
    props: {
        description: String,
        productUrl: String,
        coverImg: String, // default cover image of product
        variantJson: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            api: new Api(),
            isLoading: false,
            variants: [],
            activeVariant: null,
        }
    },
    computed: {
        activeShopwareItem() {
            const activeVariant = this.variants.find(variant => variant.id === this.activeVariant);
            return activeVariant ?? null;
        },
        showButton() {
            return this.activeShopwareItem !== null && this.activeShopwareItem !== undefined && this.statusCheck(this.activeShopwareItem.status);
        }
    },
    created() {
        this.variants = JSON.parse(this.variantJson);

        // Set the default active variant if necessary
        if (this.variants.length > 0) {
            this.activeVariant = this.variants[0].id;
        }

        EventBus.$on("variant-selected", this.changeVariant);
    },
    methods: {
        changeVariant(id) {
            this.activeVariant = id;
        },

        getVariantLabel(variant) {
            return (
                variant.data.base_unit ||
                variant.data.Oppervlakte_bereik ||
                "Unknown"
            );
        },

        getVariantCover(variant) {
            // use coverImg as fallback when no packshot is available for variant
            if (variant.packshots) {
                const keys = Object.keys(variant.packshots);
                if (keys.length > 0) {
                    return variant.packshots[keys[0]];
                }
            }

            return this.coverImg;
        },

        getVariantStatus(variant) {
            return variant.status;
        },

        async addToCart() {
            try {
                this.isLoading = true;

                const session = await this.api.client.invoke("addLineItem post /checkout/cart/line-item", {
                    items: [
                        {
                            id: this.activeShopwareItem.shopwareId,
                            modified: true,
                            quantity: 1,
                            type: "product",
                            good: "true",
                            payload: {
                                productUrl: this.productUrl,
                                description: this.description,
                                variant: this.getVariantLabel(this.activeShopwareItem),
                                coverImg: this.getVariantCover(this.activeShopwareItem),
                                stockStatus: this.getVariantStatus(this.activeShopwareItem)
                            }
                        }
                    ]
                });

                EventBus.$emit("cart-updated", session);
            } catch (error) {
                EventBus.$emit('trigger-alert', {
                    message: this.getErrorMessages(error)[0],
                    state: 'error'
                });
            } finally {
                this.isLoading = false;
            }

        }
    }
};
</script>
